import { Redirect, Route, useLocation } from "react-router-dom";
import { ConvertPathLocalToMenu } from "../components/utils/format-value";
import { CheckTokenExpire } from "../components/utils/check-value";

const AuthProtected = (props) => {
    const location = useLocation();
    const tokenData = (!localStorage.getItem("tkd")) ? null : localStorage.getItem("tkd");
    const emailData = (!localStorage.getItem("emd")) ? null : localStorage.getItem("emd");
    const menuData = (!localStorage.getItem("mnd")) ? null : localStorage.getItem("mnd");
    if(menuData === "" || menuData === null || typeof(menuData) === "undefined" 
        || tokenData === "" || tokenData === null || typeof(tokenData) === "undefined"
        || emailData === "" || emailData === null || typeof(emailData) === "undefined"){
        return(
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        );
    }else{
        //Check Token Expire
        const tokenExpire = CheckTokenExpire(tokenData);
        if(tokenExpire === true){
            return(
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );
        }

        // //Check authen in page
        const arrMenu = JSON.parse(atob(menuData));
        let localPathName = ConvertPathLocalToMenu(location.pathname, false);
        const haveMenu = arrMenu.find((obj) => obj.page_url === localPathName);
        if(typeof(haveMenu) === "undefined"){
            return(
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );
        }
    }
    return <>{props.children}</>
};

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route { ...rest }
            render={props => {
                return (<> <Component {...props} /> </>);
            }}
        />
    );
};

export { AuthProtected, AccessRoute }