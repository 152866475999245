import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Sidebar from './sidebar';
import Header from './header';

const MasterPage = (props) => {
    return (
        <React.Fragment>
            {/* <div id="layout-wrapper" data-layout="vertical" data-sidebar="dark"> */}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
};

MasterPage.propTypes = {
    children: PropTypes.object,
};

export default withRouter(MasterPage);