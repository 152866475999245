// const IconAlertSuccess = `
//         <div class="sa-success">
//             <div class="sa-success-tip"></div>
//             <div class="sa-success-long"></div>
//             <div class="sa-success-placeholder"></div>
//             <div class="sa-success-fix"></div>
//         </div>`;

const IconAlertSuccess = `
        <div class="sweetalert-success">
            <i class="mdi mdi-check"></i>
        </div>`;

const IconAlertError = `
        <div class="sweetalert-error">
            <i class="mdi mdi-close"></i>
        </div>`;

const IconAlertReturn = `
        <div class="sweetalert-return">
            <i class="mdi mdi-keyboard-return"></i>
        </div>`;

const IconAlertReject = `
        <div class="sweetalert-reject">
            <i class="mdi mdi-undo"></i>
        </div>`;

const IconAlertCancel = `
        <div class="sweetalert-cancel">
            <i class="mdi mdi-sim-alert"></i>
        </div>`;

const IconAlertSubmitCancel = `
        <div class="sweetalert-submit-cancel">
            <i class="mdi mdi-timer-sand-empty"></i>
        </div>`;


export { IconAlertSuccess, IconAlertReject, IconAlertCancel, IconAlertSubmitCancel, IconAlertError, IconAlertReturn };