import React, { useContext, useEffect, useState } from 'react';
import StepWorkflow from "../../components/common/step-workflow";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, FormGroup, Label, Input, Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import axios from "axios";
import Swal from "sweetalert2";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from '../../config.json';
import RequestFormCsa from "../../components/request/requestForm/request_form_csa";
import { getFilterOldFileName, setFormDataUploadFile } from '../../components/utils/format-value';
import { modelRequestWorkFlow } from "../../components/request/model_request";
import { IconAlertSuccess, IconAlertError } from '../../components/common/popup-alert';

const RequestDetail = () => {
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [activeStep, setActiveStep] = useState(null);
    const { idRequest } = useParams();
    const [dataRequestWorkFlow, setDataRequestWorkFlow] = useState(modelRequestWorkFlow);
    const [isShowModalApprove, setIsShowModalApprove] = useState();
    const [isShowModalSubmit, setIsShowModalSubmit] = useState();
    const [dataForm, setdataForm] = useState([]);
    const [requestDataAll, setRequestDataAll] = useState([]);
    const [dataFormCsa, setDataFormCsa] = useState([]);
    const [dataCompany, setDataCompany] = useState([]);
    const [dataRequest, setDataRequest] = useState([]);
    const history = useHistory();
    const userEmail = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
    // Thailand is UTC+7
    const thaiTimezoneOffset = 7 * 60;
    const offsetInMilliseconds = thaiTimezoneOffset * 60 * 1000;
    const thaiDateTime = new Date(new Date().getTime() + offsetInMilliseconds);
    // Format the date for MySQL DATETIME
    const newDateTime = thaiDateTime.toISOString().slice(0, 19).replace("T", " ");
    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Bangkok',
    });
    const [currentDay, currentMonth, currentYear] = currentDate.split('/');
    const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const newCurrentDate = formattedCurrentDate.replace(/undefined-/g, '');

    useEffect(() => {
        changeTitlePage("All Request");
        try{
            let reqId;
            if (idRequest === "Mq==") {
                reqId = "MQ==";
            } else {
                reqId = idRequest;
            }
            LoadDataRequest(reqId);
            LoadDataFormCsa(reqId);
        } catch(error) {
            history.push("/requests");
            console.log(error);
        }
    }, []);

    const LoadDataRequest = (idRequests) => {
      let url = ConfigData.API_URL + "request/" + atob(idRequests);
      let token = "Bearer " + localStorage.getItem("tkd");
      axios.get(url, { headers: { 
          'Authorization' : token ,
      }, responseType: "json" })
      .then(resp => {
          let respData = resp.data;
          if(respData.is_sucess === true){
              const arrDataAll = respData.data[0];
              setRequestDataAll(arrDataAll);
              LoadDataUser(arrDataAll.request_by);
              
              if(arrDataAll.request_no === "draft" && arrDataAll.id_workflow_status === 0){
                setActiveStep(1);
              } else if(arrDataAll.id_workflow_status === 3){
                setActiveStep(3);
              } else {
                setActiveStep(2);
              }
          }
      })
      .catch(error => {
          // handlerRespError(error, history);
          console.log(error);
      });
    };

    const LoadDataFormCsa = (idRequests) => {
        let url = ConfigData.API_URL + "form-csa/request/" + atob(idRequests);
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrData = respData.data[0];
                setDataFormCsa(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataUser = (email) => {
        let url = ConfigData.API_URL + "user/search?status=1&email=" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataCompany(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const onCancelRequest = (data) => {
        Swal.fire({
            title: 'Are you sure ?',
            text: 'Once you delete request draft, you can not undo it!',
            showCancelButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#dc3741',
            showCloseButton: true,
        }).then((result) => {
            if(result.isConfirmed){
                Swal.fire({
                    icon: 'info',
                    title: 'Loading...',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    willOpen: () => {
                        Swal.showLoading();
                    }
                });
          
                let isSaveSucess = true;
                (async () => {
                    try{
                        let dataCancel = await DeleteDraft(data.id);
                        isSaveSucess = (dataCancel.is_sucess === true && isSaveSucess === true) ? true : false;
                        
                        if(isSaveSucess === true){
                            Swal.close(); // Close loading spinner
                            Swal.fire({
                                iconHtml: IconAlertSuccess,
                                title: "Delete request draft.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                
                                history.push("/requests");
                            });
                        }else{
                            Swal.close(); // Close loading spinner
                            Swal.fire({
                                iconHtml: IconAlertError,
                                title: "Failed to delete data.",
                                showConfirmButton: false,
                                showCloseButton: true,
                            });
                        }
                    } catch(error){
                        console.log(error);
                    }
                })();
            }
        });
    }

    const onClickOk = (data) => {
        // Convert currentDate
        // console.log(data);
        let idWorkFlow; 
        let setRequestsNo;
        let newRequestDate;

        let newFormatStartDate = null;
        let newFormatEndDate = null;

        if(data.term_start_date !== null && data.term_end_date !== null){
             // Convert term_start_date format
            const [startDay, startMonth, startYear] = data.term_start_date.split('/');
            const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
            newFormatStartDate = formattedStartDate.replace(/undefined-/g, '');
            // Convert term_end_date format
            const [endDay, endMonth, endYear] = data.term_end_date.split('/');
            const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
            newFormatEndDate = formattedEndDate.replace(/undefined-/g, '');
        }

        // Convert request_date format
        const [requestDay, requestMonth, requestYear] = requestDataAll.request_date.split('/');
        const formattedRequestDate = `${requestYear}-${requestMonth}-${requestDay}`;
        const newRequestDates = formattedRequestDate.replace(/undefined-/g, '');

        if( data.id_workflow_status === 4){
            idWorkFlow = 2;
            setRequestsNo = requestDataAll.request_no;
            newRequestDate = newRequestDates;
        } else if( data.id_workflow_status === 0){
            idWorkFlow = 1;
            setRequestsNo = "";
            newRequestDate = newCurrentDate;
        }
        //  else {
        //     idWorkFlow = 1;
        //     setRequestsNo = requestDataAll.request_no;
        //     newRequestDate = newCurrentDate;
        // }

        // Set the converted date back to the values object
        data.term_start_date = newFormatStartDate;
        data.term_end_date = newFormatEndDate;

        const postdataRequest = {
            ...dataRequest,
            id: parseInt(atob(idRequest)),
            id_form_temp: requestDataAll.id_form_temp !== "" ? requestDataAll.id_form_temp : "",
            table_name: requestDataAll.table_name !== "" ? requestDataAll.table_name : "",
            id_workflow_status: idWorkFlow,
            request_no: setRequestsNo,
            request_date: newRequestDate,
            id_business_unit : data.id_business_unit,
            business_unit_other : data.business_unit_other,
            id_confidential_level : data.id_confidential_level ? data.id_confidential_level : "0",
            counterparty_name : data.counterparty_name,
            request_by: userEmail,
            require_handled_by: data.require_handled_by,
            approve_request_by: data.approve_request_by,
            status: "1"
        };
        // console.log(postdataRequest);
        setIsShowModalSubmit(!isShowModalSubmit);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        let isSaveSucess = true;
        (async () => {
            try{
                let dataUpdateRequestDraft = await UpdateRequestDraft(postdataRequest);
                isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                // console.log(dataUpdateRequestDraft);
                // console.log(isSaveSucess);
                // const dataFormNda = {
                //     "id_request": dataAddRequest.data[0].id,
                //     "id_business_unit": 1,
                //     "id_request_type": 1,
                //     "id_sub_request_type" : 1,
                //     "id_sub_division_request_type" : 1,
                //     "id_language" : 1,
                //     "id_confidential_level" : 1,
                //     "is_have_draft" : "0",
                //     "doc_have_draft" : "0",
                //     "counterparty_name" : "test.pdf",
                //     "purpose" : "test",
                //     "beginning_date" : "2023-10-30",
                //     "share_pdpa" : "1",
                //     "user_comment" : "test comment",
                //     "consider_doc" : "bla bla bla",
                //     "require_support_docs" : "support,test",
                //     "require_support_doc_other" : null
                // };

                // let isFormNda = await AddFormNda(dataFormNda);
                // isSaveSucess = (isFormNda === true && isSaveSucess === true) ? true : false;

                // set date to post formcsa
                const postDataFormCsa = {
                    ...data,
                    id_request: dataUpdateRequestDraft.data[0].id,
                    id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                    request_date: newRequestDate,
                    term_start_date: newFormatStartDate,
                    term_end_date: newFormatEndDate,
                    id_language: parseInt(data.id_language),
                    id_confidential_level: data.id_confidential_level ? data.id_confidential_level : "0",
                    approver_comment: data.approver_comment,
                };
                // console.log(postDataFormCsa);
                let dataFormCsa = await UpdateDraftFormCsa(postDataFormCsa);
                isSaveSucess = (dataFormCsa.is_sucess === true && isSaveSucess === true) ? true : false;
                // console.log(dataFormCsa);
                // console.log(isSaveSucess);
                let isFileName;
                if(idWorkFlow === 1){ // request draft to set to used
                    isFileName = await SetFileName(dataFormCsa.data[0].id, "draftFinal/"+dataUpdateRequestDraft.data[0].request_no, data);
                } else {
                    isFileName = await SetFileName(dataFormCsa.data[0].id, dataUpdateRequestDraft.data[0].request_no, data);
                }
                
                isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;
                // console.log(isFileName);
                // console.log(isSaveSucess);

                const postdataRequestWorkFlow = {
                    ...dataRequestWorkFlow,
                    id_request: dataUpdateRequestDraft.data[0].id,
                    send_to: dataUpdateRequestDraft.data[0].approve_request_by,
                    do_date: newDateTime,
                    do_by: userEmail,
                    finish_date : null,
                    id_workflow_status: idWorkFlow,
                    comment : idWorkFlow === 2 ? data.user_comment : null,
                };

                let getDataRequestWorkFlow = await AddRequestWorkFlow(postdataRequestWorkFlow);
                isSaveSucess = (getDataRequestWorkFlow.is_sucess === true && isSaveSucess === true) ? true : false;
                // console.log(isSaveSucess);
                // console.log(getDataRequestWorkFlow);

                if(isSaveSucess === true){
                    let isSendMail = await SendEmailAddRequest(getDataRequestWorkFlow.data[0]);
                    // let isSendMail = true;
                    // console.log(isSendMail);
                    if( isSendMail === true ){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                                iconHtml: IconAlertSuccess,
                                title: "Sending request to approver.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                                // props.nextStep();
                                history.push("/requests");
                            });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to send email to approver.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                }
            }catch(error){
                console.log(error);
            }
        })();
    };

    const onUpdateFormCsa = (data) => {
        // Convert term_start_date format
        let newFormatStartDate = null;
        let newFormatEndDate = null;

        if(data.term_start_date !== null && data.term_end_date !== null){
            // Convert term_start_date format
           const [startDay, startMonth, startYear] = data.term_start_date.split('/');
           const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
           newFormatStartDate = formattedStartDate.replace(/undefined-/g, '');
           // Convert term_end_date format
           const [endDay, endMonth, endYear] = data.term_end_date.split('/');
           const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
           newFormatEndDate = formattedEndDate.replace(/undefined-/g, '');
       }

        // if request is draft
        if(data.request_no === "draft"){
            data.term_start_date = newFormatStartDate;
            data.term_end_date = newFormatEndDate;
            const postdataRequest = {
                ...dataRequest,
                id: parseInt(atob(idRequest)),
                id_form_temp: requestDataAll.id_form_temp !== "" ? requestDataAll.id_form_temp : "",
                table_name: requestDataAll.table_name !== "" ? requestDataAll.table_name : "",
                id_workflow_status: 0,
                request_no: "draft",
                request_date: newCurrentDate,
                id_business_unit : data.business_unit_other !== "" ? "-1" : data.id_business_unit,
                business_unit_other : data.business_unit_other,
                id_confidential_level : data.id_confidential_level ? data.id_confidential_level : "0",
                counterparty_name : data.counterparty_name,
                request_by: userEmail,
                require_handled_by: data.require_handled_by,
                approve_request_by: data.approve_request_by,
                status: "1"
            };

            // console.log(postdataRequest);

            let isSaveSucess = true;
            (async () => {
                try{
                    let dataUpdateRequestDraft = await UpdateRequestDraft(postdataRequest);
                    isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataUpdateRequestDraft);
                    // console.log(isSaveSucess);

                    const {
                        // file_consider_doc,
                        // file_is_sla_doc,
                        // file_scope_of_work_doc,
                        // file_counterparty_doc,
                        // file_draft_doc,
                        approve_request_by,
                        require_handled_by,
                        // request_no,
                        ...postDataFormCsa //delete data json
                    } = data;

                    // console.log(postDataFormCsa);

                    const updatedDataCompany = {
                        ...postDataFormCsa,
                        id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                    };

                    let dataFormCsa = await UpdateFormCsa(updatedDataCompany);
                    isSaveSucess = (dataFormCsa.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataFormCsa);

                    let isFileName = await SetFileName(dataFormCsa.data[0].id, data.request_no, data);
                    isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;
                    // console.log(isSaveSucess);

                    let changeRequiredHandle = await UpdateRequiredHandle(dataFormCsa.data[0].id , data);
                    isSaveSucess = (changeRequiredHandle.is_sucess === true && isSaveSucess === true) ? true : false;
                    
                    if(isSaveSucess === true){
                        Swal.fire({
                            iconHtml: IconAlertSuccess,
                            title: "Save draft success.",
                            showConfirmButton: false,
                            showCloseButton: true,
                            // showConfirmButton: false,
                        }).then(() => {
                            // props.nextStep();
                            // history.push("/request/" + btoa(dataAddRequest.data[0].id));
                            // window.location.reload();
                        });
                    }else{
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to save draft data.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                } catch(error){
                    console.log(error);
                }
            })();
        } else {
            let isSaveSucess = true;
            // console.log(data);
            (async () => {
                try{
                    let dataFormCsa;
                    const {
                        // file_consider_doc,
                        // file_is_sla_doc,
                        // file_scope_of_work_doc,
                        // file_counterparty_doc,
                        // file_draft_doc,
                        approve_request_by,
                        require_handled_by,
                        // request_no,
                        ...postDataFormCsa
                    } = data;
                    if(data.business_unit_other !== "" && data.id_business_unit === "-1") {
                        const postdataRequest = {
                            ...dataRequest,
                            id: parseInt(atob(idRequest)),
                            id_business_unit : data.business_unit_other !== "" ? "-1" : data.id_business_unit,
                            business_unit_other : data.business_unit_other,
                            id_confidential_level: data.id_confidential_level,
                        };
                        let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                        isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                        // console.log(dataUpdateRequestDraft);

                        const updatedDataCompany = {
                            ...postDataFormCsa,
                            id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                        };
                        dataFormCsa = await UpdateFormCsa(updatedDataCompany);
                    } else {
                        const postdataRequest = {
                            ...dataRequest,
                            id: parseInt(atob(idRequest)),
                            id_business_unit : data.business_unit_other !== "" ? "-1" : data.id_business_unit,
                            business_unit_other : data.business_unit_other,
                            id_confidential_level: data.id_confidential_level,
                        };
                        // console.log(postdataRequest);
                        let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                        isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                        
                        dataFormCsa = await UpdateFormCsa(postDataFormCsa);
                    }
                    // let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                    // isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataUpdateRequestDraft);

                    // const updatedDataCompany = {
                    //     ...postDataFormCsa,
                    //     id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                    // };

                    // console.log(postDataFormCsa);
                    
                    isSaveSucess = (dataFormCsa.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataFormCsa);

                    let isFileName = await SetFileName(dataFormCsa.data[0].id, data.request_no, data);
                    isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;
                    // console.log(isSaveSucess);

                    let changeRequiredHandle = await UpdateRequiredHandle(dataFormCsa.data[0].id , data);
                    isSaveSucess = (changeRequiredHandle.is_sucess === true && isSaveSucess === true) ? true : false;
                    
                    if(isSaveSucess === true){
                        Swal.fire({
                            iconHtml: IconAlertSuccess,
                            title: "Save success.",
                            showConfirmButton: false,
                            showCloseButton: true,
                            // showConfirmButton: false,
                        }).then(() => {
                            // props.nextStep();
                            // history.push("/request/" + btoa(dataAddRequest.data[0].id));
                        });
                    }else{
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to save data.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                } catch(error){
                    console.log(error);
                }
            })();
        }
        
    };

    const onApprove = (data) => {
        // console.log(dataForm);
        setIsShowModalApprove(!isShowModalApprove);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        let isSaveSucess = true;
        (async () => {
            try{
                let dataUpdateRequest = await UpdateRequest(data.id);
                isSaveSucess = (dataUpdateRequest.is_sucess === true && isSaveSucess === true) ? true : false;
                let dataFormCsa;
                if(dataForm.business_unit_other !== "" && dataForm.id_business_unit === "-1") {
                    const postdataRequest = {
                        ...dataRequest,
                        id: parseInt(atob(idRequest)),
                        id_business_unit : dataForm.business_unit_other !== "" ? "-1" : dataForm.id_business_unit,
                        business_unit_other : dataForm.business_unit_other,
                    
                    };
                    let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                    isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataUpdateRequestDraft);

                    const updatedDataCompany = {
                        ...dataForm,
                        id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                    };
                    dataFormCsa = await UpdateFormCsa(updatedDataCompany);
                } else {
                    dataFormCsa = await UpdateFormCsa(dataForm);
                }
                isSaveSucess = (dataFormCsa.is_sucess === true && isSaveSucess === true) ? true : false;

                let emailLegalAdmin = Array.isArray(dataForm.legal_admin) ? dataForm.legal_admin.map(item => item.email).join(',') : '';
                let emailLegalAuthorized = Array.isArray(dataForm.legal_authorized) ? dataForm.legal_authorized.map(item => item.email).join(',') : '';
                let requireHandledBy = dataForm.require_handled_by;
                // Filter out empty values and concatenate
                let combinedEmails = [emailLegalAdmin, emailLegalAuthorized, requireHandledBy].filter(Boolean).join(',');

                let changeRequiredHandle = await UpdateRequiredHandle(data.id , dataForm);
                isSaveSucess = (changeRequiredHandle.is_sucess === true && isSaveSucess === true) ? true : false;
                
                const dataRequestWorkFlows = {
                    "id_request": data.id,
                    "id_workflow_status" : 3,
                    "do_by" : userEmail,
                    "send_to" : combinedEmails,
                    "cc_to" : null,
                    "comment" : null,
                    "document" : null,
                    "consent" : null,
                    "do_date" : newDateTime,
                    "finish_date" : newDateTime,
                };

                let dataRequestWorkFlow = await AddRequestWorkFlow(dataRequestWorkFlows);
                isSaveSucess = (dataRequestWorkFlow.is_sucess === true && isSaveSucess === true) ? true : false;
                
                if(isSaveSucess === true){
                    let isSendMail = await SendMailApproveToLegal(dataRequestWorkFlow.data[0]);
                    if( isSendMail === true ){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                                iconHtml: IconAlertSuccess,
                                title: "Sending request to Legal Team.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                                // props.nextStep();
                                history.push("/requests");
                            });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to send email to Legal Team.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                }
            } catch(error){
                console.log(error);
            }
        })();
    };

    const AddRequestWorkFlow = async (dataRequestWorkFlows) => {
        let url = ConfigData.API_URL + "request-workflow/";
        let data = dataRequestWorkFlows;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.post(url, data, { headers: { 
                                'Authorization' : token,
                                'User': userId,
                            }, responseType: "json" }
                        );
            let respData = resp.data;
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequestDraft = async (data) => {
        let url = ConfigData.API_URL + "request/draft/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        if(data.id_business_unit === ""){
            data.id_business_unit = "-1";
        }
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequests = async (data) => {
        let url = ConfigData.API_URL + "request/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateFormCsa = async (data) => {
        let url = ConfigData.API_URL + "form-csa/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        if(data.id_confidential_level === ""){
            data.id_confidential_level = "0";
        }   
        if(data.term_start_date === ""){
            data.term_start_date = null;
        }  
        if(data.term_end_date === ""){
            data.term_end_date = null;
        }  
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            // console.log(respData);
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequest = async (idRequest) => {
        let url = ConfigData.API_URL + "request/approve-request-form/" + idRequest;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url,{}, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    
    const UpdateRequiredHandle = async (id, data) => {
        // console.log(data);
        let url = ConfigData.API_URL + "request/change-legal-identified/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateDraftFormCsa = async (dataFormCsa) => {
        let url = ConfigData.API_URL + "form-csa/draft/" + dataFormCsa.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        if(dataFormCsa.term_start_date === ""){
            dataFormCsa.term_start_date = null;
        }  
        if(dataFormCsa.term_end_date === ""){
            dataFormCsa.term_end_date = null;
        } 
        // console.log(dataFormCsa);
        try {
            const resp = await axios.put(url, dataFormCsa, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const DeleteDraft = async (id) => {
        let url = ConfigData.API_URL + "request/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.delete(url, {
                headers: {
                    'Authorization': token,
                    'User': userId,
                },
                responseType: "json",
            });
            let respData = resp.data;
            // Handle respData as needed
            return respData;
        } catch (error) {
            // Handle error as needed
            console.log(error);
            return false;
        }
    };

    const SetFileName = async (id, requestNo, data) => {
        let url = ConfigData.API_URL + "form-csa/set-filename/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let formData = new FormData();
        formData.append("request_no", requestNo);
        formData.append("n_draft_doc", getFilterOldFileName(data.file_draft_doc));
        formData.append("n_counterparty_doc", getFilterOldFileName(data.file_counterparty_doc));
        formData.append("n_is_sla_doc", getFilterOldFileName(data.file_is_sla_doc));
        formData.append("n_scope_of_work_doc", getFilterOldFileName(data.file_scope_of_work_doc));
        formData.append("n_consider_doc", getFilterOldFileName(data.file_consider_doc));
        formData = setFormDataUploadFile(formData, "f_draft_doc", data.file_draft_doc);
        formData = setFormDataUploadFile(formData, "f_counterparty_doc", data.file_counterparty_doc);
        formData = setFormDataUploadFile(formData, "f_is_sla_doc", data.file_is_sla_doc);
        formData = setFormDataUploadFile(formData, "f_scope_of_work_doc", data.file_scope_of_work_doc);
        formData = setFormDataUploadFile(formData, "f_consider_doc", data.file_consider_doc);
        try{
            const resp = await axios.put(url, formData, { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            // console.log(respData);
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const SendEmailAddRequest = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 1,
            "request_id": data.id_request,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };
  
    const SendMailApproveToLegal = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 3,
            "request_id": data.id_request,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const onCheckApprove = (data) => {
        setdataForm(data);
        // console.log(data);
        if(data.request_no === "draft" || data.id_workflow_status === 4){
            setIsShowModalSubmit(true);
        } else {
            setIsShowModalApprove(true);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                 {/* Request Detail{" > "+requestDataAll.request_no} */}
                    <Row>
                        <Col xl={12}>
                            <div>
                                <StepWorkflow step={activeStep} />
                                    {/* check if step 1 , 2 , 4 */}
                                {requestDataAll.request_no === "draft" || requestDataAll.id_workflow_status === 1 || requestDataAll.id_workflow_status === 2 || requestDataAll.id_workflow_status === 4 ? (
                                    <div>
                                    {/* start component type information */}
                                        <Row>
                                            <Col xl={10} className="mx-auto">
                                                <div>
                                                    <span className="span-type-request">ข้อมูลของผู้ทำคำขอ (User Information)</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Col md={10} className="mx-auto">
                                            <Row>
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label className="request_label_nowrap">คำขอเลขที่ (Request No.)</Label>
                                                        <Input
                                                            type="text"
                                                            name="requestNo"
                                                            placeholder=""
                                                            // onChange={onInputChanged}
                                                            value={requestDataAll.request_no || ''}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label className="request_label_nowrap">วันที่สร้างคำขอ (Date of Request)</Label>
                                                        <Input
                                                        type="text"
                                                        name="dateOfRequest"
                                                        placeholder=""
                                                        // onChange={onInputChanged}
                                                        value={requestDataAll.request_date || ''}
                                                        disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={10} className="mx-auto">
                                            <Row>
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label htmlFor="ddlBu" className="request_label">บริษัทที่สังกัด (User's BU)</Label>
                                                        <Input
                                                        type="text"
                                                        name="businessUnit"
                                                        placeholder=""
                                                        // onChange={onInputChanged}
                                                        value={dataCompany.business_unit || ""}
                                                        disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label htmlFor="ddlDepartment" className="request_label">แผนกที่สังกัด (User's Department)</Label>
                                                        <Input
                                                        type="text"
                                                        name="department"
                                                        placeholder=""
                                                        // onChange={onInputChanged}
                                                        value={dataCompany.department || ""}
                                                        disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="line-border"></div>
                                                </Col>
                                                <Col md={12}>
                                                    {requestDataAll.table_name === "csa" || requestDataAll.table_name === "CSA" || requestDataAll.id_form_temp === 1 ? (
                                                        <RequestFormCsa dataFormCsa={dataFormCsa} dataFormRequest={requestDataAll} onSaveDraft={onUpdateFormCsa} onSave={onCheckApprove} onCancelRequest={onCancelRequest}/>
                                                    ) : (
                                                        <div>form nda</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    {/* end component user information */}
                                        <br />
                                    {/* check state button is request_no = draft ? */}
                                    {/* หน้านี้ จะมี action ปุ่ม approve ส่วน จะมี ปุ่ม action submit ก็ต่อเมื่อ มี การ return กลับมาแล้วเท่านั้น */}
                                        
                                    </div>
                                ): "Legal Review" } {/* check if step 3 หมายถึง approver approve request เรียบร้อยแล้ว จะไปที่หน้า review ต่อไป */}
                                {/* </StepWizard> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
             {/* Modal Add and Edit */}
            <Modal id="modalApprove"
                isOpen={isShowModalApprove}
                toggle={() => { setIsShowModalApprove(!isShowModalApprove); }}
                backdrop={'static'}
                centered
            >
                {/* <ModalHeader id="modalBUHeader" 
                    className="modal-title"
                    toggle={() => { setIsShowModal(!isShowModal); }}
                >
                </ModalHeader> */}
                <ModalBody>
                <Row>
                    <p><i className="mdi mdi-alert-decagram modal-alert"></i></p>
                    <p className="modal-note">Please note</p>
                    <p className="modal-content-approve">that the information provided in the request form is only a preliminary information for review/drafting process.<br/>Legal team may request for more information at a later stage.</p> 
                </Row>
                </ModalBody>
                <ModalFooter>
                <Row>
                    <Col md="12">
                        <Button 
                            className="float-end" 
                            color="primary" 
                            type="button"
                            onClick={() => onApprove(requestDataAll)}
                        >
                            Ok
                        </Button>
                        <Button 
                            className="float-end" 
                            color="outline secondary" 
                            type="button" 
                            onClick={() => setIsShowModalApprove(false) }  
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                </ModalFooter>
            </Modal>
             
            <Modal id="modalSubmit"
                isOpen={isShowModalSubmit}
                toggle={() => { setIsShowModalSubmit(!isShowModalSubmit); }}
                backdrop={'static'}
                centered
            >
                <ModalBody>
                        <Row>
                            <p><i className="mdi mdi-alert-decagram modal-alert"></i></p>
                            <p className="modal-note">Please note</p>
                            <p className="modal-content-approve">that the information provided in the request form is only a preliminary information for review/drafting process.<br/>Legal team may request for more information at a later stage.</p> 
                        </Row>
                </ModalBody>
                <ModalFooter>
                        <Row>
                            <Col md="12">
                                <Button 
                                    className="float-end" 
                                    color="primary" 
                                    type="button"
                                    onClick={() => onClickOk(dataForm)}
                                >
                                    Ok
                                </Button>
                                <Button 
                                    className="float-end" 
                                    color="outline secondary" 
                                    type="button" 
                                    onClick={() => setIsShowModalSubmit(false) } 
                                    
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                </ModalFooter>
            </Modal>
            
        </React.Fragment>
    );
};

export default RequestDetail;