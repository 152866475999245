const CheckTokenExpire = (tokens) => {
    const decodeJwt = ParseJwt(tokens);
    if((decodeJwt.exp * 1000) < Date.now()){
        return true;
    }
    return false;
};

const ParseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

const getStepWorkflowByIdWorkFlow = (idWorkflow) => {
    const arrStep = [
        {"step": 1, "id_work_flow": [1]},
        {"step": 2, "id_work_flow": [2,3,4]},
        {"step": 3, "id_work_flow": [5,6,7]},
        {"step": 4, "id_work_flow": [8,12]},
        {"step": 5, "id_work_flow": [9,11,13,14]},
        {"step": 6, "id_work_flow": [10]},
        {"step": 7, "id_work_flow": [15,16,17]},
        {"step": 8, "id_work_flow": [18,19,20]},
        {"step": 9, "id_work_flow": [21]},
        {"step": 10, "id_work_flow": [22]}
    ];
    const step = arrStep.find((e) => e.id_work_flow.includes(idWorkflow));
    return (step !== undefined) ? step.step : 0;
};

const getServiceFee = (serviceFee) => {
    var price = parseFloat(serviceFee);
    var result = !isNaN(price) ? Number(serviceFee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-";
    return result;
};

export { CheckTokenExpire, ParseJwt, getStepWorkflowByIdWorkFlow, getServiceFee };