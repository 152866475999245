import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import TableModify from "../../components/common/table-modify";
import Select from "react-select";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ConfigData from '../../config.json';


const UserList = () => {
    const { id } = useParams();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [isShowModal, setIsShowModal] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [dataBU, setDataBU] = useState([]);
    const [selectBU, setSelectBU] = useState("");
    const [dataUserGroup, setDataUserGroup] = useState([]);
    const [selectUserGroup, setSelectUserGroup] = useState("");
    const [dataDetail, setDataDetail] = useState({
        id: 0,
        id_business_unit: 0,
        id_user_group: 0,
        email: "",
        name: "",
        surname: "",
        department: "",
    });

    useEffect(() => {
        // console.log(id);
        changeTitlePage("Users");
        LoadDataTable();
        LoadDataBusinessUnit();
        LoadDataUserGroup();
    }, []);

    const column = [
        {
            key: "id",
            name: <span className='font-weight-bold fs-13'>No.</span>,
            cell: (row, index) => index+1,
            width: "5%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: false
        },
        {
            key: "business_unit",
            name: <span className='font-weight-bold fs-13'>Business Unit</span>,
            cell: (row, index) => row.business_unit,
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "name",
            name: <span className='font-weight-bold fs-13'>Firstname</span>,
            cell: (row, index) => row.name,
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "surname",
            name: <span className='font-weight-bold fs-13'>Lastname</span>,
            cell: (row, index) => row.surname,
            width: "25%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "email",
            name: <span className='font-weight-bold fs-13'>Username</span>,
            cell: (row, index) => row.email,
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "action",
            name: <span className='font-weight-bold fs-13'></span>,
            cell: (row, index) => {
                return(
                    <UncontrolledDropdown key={index}>
                        <DropdownToggle tag="button" className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="ri-more-2-fill"></i></DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <li>
                                <DropdownItem onClick={() => onClickUpdate(row)}>
                                    <i className="ri-pencil-fill"></i> Edit
                                </DropdownItem>
                            </li>
                            <li>
                                <DropdownItem onClick={() => onClickDelete(row.id)}>
                                    <i className="ri-delete-bin-line"></i> Delete
                                </DropdownItem>
                            </li>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )
            },
            width: "10%",
            classHeader: "text-start",
            classBody: "text-end",
            sortable: false
        }
    ];

    const onClickAdd = () => {
        validation.resetForm();
        setSelectBU(null);
        setDataDetail({
            id: 0,
            id_business_unit: 0,
            id_user_group: 0,
            email: "",
            name: "",
            surname: "",
            department: "",
        });
        setIsShowModal(!isShowModal);
    };

    const onClickUpdate= (data) => {
        console.log(data);
        validation.resetForm();
        setSelectBU(dataBU.find(x => x.value === data.id_business_unit.toString()));
        setSelectUserGroup(dataUserGroup.find(x => x.value === data.id_user_group.toString()));
        setDataDetail({
            id: data.id,
            id_business_unit: data.id_business_unit,
            id_user_group: data.id_user_group,
            email: data.email,
            name: data.name,
            surname: data.surname,
            department: data.department,
        });
        setIsShowModal(!isShowModal);
    };

    const onClickDelete = (id) => {
        Swal.fire({
            title: 'Are you sure ?',
            text: 'Once you delete, you can not undo it!',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#dc3741',
        }).then((result) => {
            if(result.isConfirmed){
                DeleteUser(id);
            }
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataDetail.id || 0,
            id_business_unit: dataDetail.id_business_unit || "",
            id_user_group: dataDetail.id_user_group || "",
            email: dataDetail.email || "",
            name: dataDetail.name || "",
            surname: dataDetail.surname || "",
            department: dataDetail.department || "",
        },
        validationSchema: Yup.object({
            id_business_unit: Yup.string().required("Please select a Business Unit"),
            id_user_group: Yup.string().required("Please select a Group"),
            email: Yup.string().required("Please enter a Username"),
            name: Yup.string().required("Please enter a Firstname"),
            surname: Yup.string().required("Please enter a Lastname"),
            department: Yup.string().required("Please enter a Department"),
        }),
        onSubmit: (values) => {
            values.id_business_unit = (values.id_business_unit !== "") ? parseInt(values.id_business_unit) : 0;
            values.id_user_group = (values.id_user_group !== "") ? parseInt(values.id_user_group) : 0;
            if(values.id > 0){
                UpdateUser(values);
            }else{
                AddUser(values);
            }
        }
    });

    const LoadDataTable = () => {
        let url = ConfigData.API_URL + "user/search?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataTable(respData.data);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataBusinessUnit = () => {
        let url = ConfigData.API_URL + "business-unit/search?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name_en,
                        value: e.id.toString()
                    };
                });
                setDataBU(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataUserGroup = () => {
        let url = ConfigData.API_URL + "user-group/?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name,
                        value: e.id.toString()
                    };
                });
                setDataUserGroup(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const AddUser = (data) => {
        let url = ConfigData.API_URL + "user/";
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        axios.post(url, data, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                LoadDataTable();
                setIsShowModal(!isShowModal);
                Swal.fire({
                    icon: 'success',
                    title: 'Add data success !',
                    confirmButtonColor: '#3085d6',
                });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to add data.',
                confirmButtonColor: '#3085d6',
            });
        });
    };

    const UpdateUser = (data) => {
        let url = ConfigData.API_URL + "user/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        axios.put(url, data, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                LoadDataTable();
                setIsShowModal(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Edit data success !',
                    confirmButtonColor: '#3085d6',
                });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to update data.',
                confirmButtonColor: '#3085d6',
            });
        });
    };

    const DeleteUser = (id) => {
        let url = ConfigData.API_URL + "user/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        axios.delete(url, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                LoadDataTable();
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success !',
                    confirmButtonColor: '#3085d6',
                });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to delete data.',
                confirmButtonColor: '#3085d6',
            });
        });
    };

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <p>{id}</p>
                                    <TableModify data={dataTable} column={column} rowPerPage={25} onClickAdd={onClickAdd} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Modal Add and Edit */}
            <Modal id="modalDepartment"
                isOpen={isShowModal}
                toggle={() => { setIsShowModal(!isShowModal); }}
                backdrop={'static'}
                centered
            >
                <ModalHeader id="modalDepartmentHeader" 
                    className="modal-title"
                    toggle={() => { setIsShowModal(!isShowModal); }}
                >
                    {(dataDetail.id <= 0) ? "Create user" : "Edit user"}
                </ModalHeader>
                <ModalBody>
                    <Form className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label className="text-muted" htmlFor="txtEmail">Email<span className="msg-require">*</span></Label>
                                    <Input id="txtEmail"
                                        name="email"
                                        placeholder="Email"
                                        className="from-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={ validation.touched.email && validation.errors.email ? true : false }
                                    />
                                    { validation.touched.email && validation.errors.email ? 
                                        ( <FormFeedback type="invalid">{validation.errors.email}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label className="text-muted" htmlFor="txtName">Firstname<span className="msg-require">*</span></Label>
                                    <Input id="txtName" 
                                        name="name"
                                        placeholder="Name"
                                        className="from-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={ validation.touched.name && validation.errors.name ? true : false }
                                    />
                                    { validation.touched.name && validation.errors.name ? 
                                        ( <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label className="text-muted" htmlFor="txtSurname">Lastname<span className="msg-require">*</span></Label>
                                    <Input id="txtSurname" 
                                        name="surname"
                                        placeholder="Surname"
                                        className="from-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.surname || ""}
                                        invalid={ validation.touched.surname && validation.errors.surname ? true : false }    
                                    />
                                    { validation.touched.surname && validation.errors.surname ? 
                                        ( <FormFeedback type="invalid">{validation.errors.surname}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label className="text-muted" htmlFor="ddlBusinessUnit">Business Unit<span className="msg-require">*</span></Label>
                                    <Select id="ddlBusinessUnit"
                                        name="id_business_unit"
                                        value={selectBU}
                                        onChange={(selected) => {
                                            setSelectBU(selected);
                                            validation.handleChange('id_business_unit')((selected !== null) ? selected.value : "");
                                        }}
                                        options={dataBU}
                                        isClearable={true}
                                        isSearchable={true}
                                        invalid={ validation.touched.id_business_unit && validation.errors.id_business_unit ? true : false }
                                    />
                                    { validation.touched.id_business_unit && validation.errors.id_business_unit ? 
                                        ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.id_business_unit}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label className="text-muted" htmlFor="txtDepartment">Department Name<span className="msg-require">*</span></Label>
                                    <Input id="txtDepartment" 
                                        name="department"
                                        placeholder="Department"
                                        className="from-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.department || ""}
                                        invalid={ validation.touched.department && validation.errors.department ? true : false }    
                                    />
                                    { validation.touched.department && validation.errors.department ? 
                                        ( <FormFeedback type="invalid">{validation.errors.department}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label className="text-muted" htmlFor="ddlBusinessUnit">Group<span className="msg-require">*</span></Label>
                                    <Select id="ddlUserGroup"
                                        name="id_user_group"
                                        value={selectUserGroup}
                                        onChange={(selected) => {
                                            setSelectUserGroup(selected);
                                            validation.handleChange('id_user_group')((selected !== null) ? selected.value : "");
                                        }}
                                        options={dataUserGroup}
                                        isClearable={true}
                                        isSearchable={true}
                                        invalid={ validation.touched.id_user_group && validation.errors.id_user_group ? true : false }
                                    />
                                    { validation.touched.id_user_group && validation.errors.id_user_group ? 
                                        ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.id_user_group}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button color="primary" type="button" onClick={() => setIsShowModal(false) } >
                                    Close
                                </Button>
                                <Button className="float-end" color="success" type="submit">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default UserList;