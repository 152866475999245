import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import "../../assets/scss/pages/_assign-work.scss";
import MultipleDownloadfile from "../common/multiple-downloadfile";
import { getServiceFee } from "../utils/check-value";
import { setStringFileNameToArray } from "../utils/format-value";
import ConfigData from "../../config.json";
import axios from "axios";
import Swal from "sweetalert2";

const AssignDetailCsa = ({ requestDetail, requestTemplateDetail }) => {
  const accessToken = localStorage.getItem("tkd");
  const [requestType, setRequestType] = useState([]);
  const [subRequestType, setSubRequestType] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [userBuDetail, setUserBuDetail] = useState([]);
  const [draftDoc, setDraftDoc] = useState([]);
  const [counterpartyDoc, setCounterpartyDoc] = useState([]);
  const [draftAgreement, setDraftAgreement] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [serviceReceiver, setServiceReceiver] = useState("");
  const [scopeOfWorkDoc, setScopeOfWorkDoc] = useState([]);
  const [isSlaDoc, setIsSlaDoc] = useState([]);
  const [considerDoc, setConsiderDoc] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [vatDetail, setVatDetail] = useState("");

  const draftAgreementOptions = [
    { value: "1", label: "ตรวจสอบสัญญา/บันทึกข้อตกลงตามแนบ (Review draft agreement as attached)" },
    { value: "2", label: "ให้ฝ่ายกฎหมายจัดทำสัญญา/บันทึกข้อตกลง (Draft agreement)" },
  ];

  const serviceTypeActAsOptions = [
    { value: "1", label: "ผู้รับจ้าง (Service Provider)" },
    { value: "2", label: "ผู้ว่าจ้าง (Service Receiver)" },
  ];

  const paymentTypeOptions = [
    { value: "1", label: "ชำระครั้งเดียว" },
    { value: "2", label: "รายงวด" },
  ];

  const vatOption = [
    { value: "1", label: "รวม VAT (includes VAT)" },
    { value: "2", label: "ไม่รวม VAT (excludes VAT)" },
    { value: "3", label: "ไม่มี VAT (is not subject to VAT)" },
  ];

  useEffect(() => {
    showLoading(true);
    LoadRequestType(requestTemplateDetail.id_request_type);
    LoadSubRequestType(requestTemplateDetail.id_sub_request_type);
    LoadUserInfo(requestDetail.request_by);

    if (Object.keys(requestTemplateDetail).length > 0) {
      setDraftAgreement(
        draftAgreementOptions.find(
          (item) => item.value === requestTemplateDetail.draft_agreement
        ).label
      );
      setServiceProvider(
        serviceTypeActAsOptions.find(
          (item) => item.value === requestTemplateDetail.business_unit_act_as
        ).label
      );
      setServiceReceiver(
        serviceTypeActAsOptions.find(
          (item) => item.value === requestTemplateDetail.counterparty_act_as
        ).label
      );
      setPaymentType(
        paymentTypeOptions.find(
          (item) => item.value === requestTemplateDetail.service_fee_payment_type
        ).label
      );
      setVatDetail(
        vatOption.find(
          (item) => item.value === requestTemplateDetail.vat
        ).label
      );
    }
    LoadAllAttachment();
    showLoading(false);
  }, [requestDetail, requestTemplateDetail]);

  const showLoading = (isShow) => {
    if (isShow) {
      Swal.fire({
        icon: "info",
        title: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  };

  const LoadAllAttachment = () => {
    if (Object.keys(requestTemplateDetail).length > 0) {
      setDraftDoc(setStringFileNameToArray(requestTemplateDetail.draft_doc));
      setCounterpartyDoc(setStringFileNameToArray(requestTemplateDetail.counterparty_doc));
      setScopeOfWorkDoc(setStringFileNameToArray(requestTemplateDetail.scope_of_work_doc));
      setIsSlaDoc(setStringFileNameToArray(requestTemplateDetail.is_sla_doc));
      setConsiderDoc(setStringFileNameToArray(requestTemplateDetail.consider_doc));
    }
  };

  //#region Call APIs

  const LoadRequestType = (requestTypeId) => {
    if (typeof requestTypeId === "undefined" || requestTypeId === null) {
      return;
    }
    let url = ConfigData.API_URL + "request-type/" + requestTypeId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestType(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadSubRequestType = (subRequestTypeId) => {
    if (typeof subRequestTypeId === "undefined" || subRequestTypeId === null) {
      return;
    }
    let url = ConfigData.API_URL + "sub-request-type/" + subRequestTypeId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setSubRequestType(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadUserInfo = (email) => {
    let url = ConfigData.API_URL + "user/search?email=" + email;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setUserDetail(respData.data[0]);
          LoadUserBuInfo(respData.data[0].id_business_unit);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadUserBuInfo = (buId) => {
    let url = ConfigData.API_URL + "business-unit/" + buId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setUserBuDetail(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //#endregion Call APIs

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} className="mx-auto">
          <div className="page-content-assign">
            <div className="header-content">
              ข้อมูลของผู้ทำคำขอ (User Information)
            </div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <Col md={4}>
                  <div className="body-content">
                    <Label className="content-name">
                      คำขอเลขที่ (Request No.)
                    </Label>
                    <Label className="content-val">
                      {requestDetail.request_no || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      ผู้ทำคำขอ (User’s Request)
                    </Label>
                    <Label className="content-val">
                      {userDetail.name + " " + userDetail.surname || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      บริษัทที่สังกัด (User's BU)
                    </Label>
                    <Label className="content-val">
                      {userBuDetail.name_en || "-"}
                    </Label>
                  </div>
                </Col>
                <Col md={4}></Col>
                <Col md={4}>
                  <div className="body-content">
                    <Label className="content-name">
                      วันที่สร้างคำขอ (Date of Request)
                    </Label>
                    <Label className="content-val">
                      {requestDetail.request_date || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      อีเมลผู้ทำคำขอ (User’s email)
                    </Label>
                    <Label className="content-val">
                      {userDetail.email || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      แผนกที่สังกัด (User's Department)
                    </Label>
                    <Label className="content-val">
                      {userDetail.department || "-"}
                    </Label>
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="header-content">
              ข้อมูลทั่วไป (Request General Information)
            </div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <Col md={4}>
                  <div className="body-content">
                    <Label className="content-name">Type of Request</Label>
                    <Label className="content-val">
                      {requestType.name_en || "-"}
                    </Label>
                  </div>
                </Col>
                <Col md={4}></Col>
                <Col md={4}>
                  <div className="body-content">
                    <Label className="content-name">Type of Contract</Label>
                    <Label className="content-val">
                      {subRequestType.name_en || "-"}
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="body-content">
                  <Label className="content-name">
                    ชื่อโครงการ (Project Name)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.project_name || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    ต้องการให้ฝ่ายกฎหมายดำเนินการ (Request Legal Team to)
                  </Label>
                  <Label className="content-val">{draftAgreement || "-"}</Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">Attachment File</Label>
                  {draftDoc.length > 0 ? (
                    <MultipleDownloadfile
                      folder={requestDetail.request_no}
                      dataFile={draftDoc}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </Row>
            </Col>
            <div className="header-content">ชื่อคู่สัญญา (The Parties)</div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <Col md={4}>
                  <div className="body-content">
                    <Label className="content-name">
                      ทำสัญญาในนามของ (Company Name)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.name_businessunit || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      ชื่อคู่สัญญาอีกฝ่าย (Counterparty's Name)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.counterparty_name || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">สำเนาประกอบ</Label>
                    {counterpartyDoc.length > 0 ? (
                      <MultipleDownloadfile
                        folder={requestDetail.request_no}
                        dataFile={counterpartyDoc}
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </Col>
                <Col md={4}></Col>
                <Col md={4}>
                  <div className="body-content">
                    <Label className="content-name">โดยเป็นฝ่าย (Act as)</Label>
                    <Label className="content-val">
                      {serviceProvider || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">โดยเป็นฝ่าย (Act as)</Label>
                    <Label className="content-val">
                      {serviceReceiver || "-"}
                    </Label>
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="header-content">
              รายละเอียดการว่าจ้าง (Service Details)
            </div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <div className="body-content">
                  <Label className="content-name">
                    วัตถุประสงค์ของการจ้าง/บริการ (Purpose)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.purpose || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    สถานที่ที่ปฏิบัติงาน (Place of Performance)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.place_of_work || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    ขอบเขต และรายละเอียดการปฏิบัติงานตามสัญญา (Scope of Work)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.scope_of_work || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    Attachment File Scope of Work
                  </Label>
                  {scopeOfWorkDoc.length > 0 ? (
                    <MultipleDownloadfile
                      folder={requestDetail.request_no}
                      dataFile={scopeOfWorkDoc}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    มาตรฐาน/ระดับการให้บริการ (Service Standards/SLA)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.is_sla === "1" ? "มี" : "ไม่มี"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    Attachment File Service Standards/SLA
                  </Label>
                  {isSlaDoc.length > 0 ? (
                    <MultipleDownloadfile
                      folder={requestDetail.request_no}
                      dataFile={isSlaDoc}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    เอกสารแนบเพื่อพิจารณา (Attachment)
                  </Label>
                  {considerDoc.length > 0 ? (
                    <MultipleDownloadfile
                      folder={requestDetail.request_no}
                      dataFile={considerDoc}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </Row>
            </Col>
            <div className="header-content">ระยะเวลาของสัญญา (Term)</div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <div className="body-content">
                  <Label className="content-name">ระยะเวลาของสัญญา</Label>
                  <Label className="content-val">
                    {requestTemplateDetail.term_start_date &&
                    requestTemplateDetail.term_end_date ? (
                      <span>
                        {new Date(
                          requestTemplateDetail.term_start_date
                        ).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}{" "}
                        -{" "}
                        {new Date(
                          requestTemplateDetail.term_end_date
                        ).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </span>
                    ) : requestTemplateDetail.term_other ? (
                      requestTemplateDetail.term_other
                    ) : (
                      "-"
                    )}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    เงื่อนไขการต่ออายุสัญญา (Renewal Term)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.term_renewal_condition || "-"}
                  </Label>
                </div>
              </Row>
            </Col>
            <div className="header-content">
              ค่าจ้าง/ค่าบริการ (Service Fee)
            </div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <Col md={6}>
                  <div className="body-content">
                    <Label className="content-name">
                      มูลค่ารวมของค่าบริการ (Total Service Fee)
                    </Label>
                    <Label className="content-val">
                      {getServiceFee(requestTemplateDetail.service_fee_total)}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      รูปแบบการชำระเงิน (Payment Term)
                    </Label>
                    <Label className="content-val">{paymentType || "-"}</Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      เครดิตการชำระเงิน หรือระยะเวลาถึงกำหนดชำระเงิน (Credit
                      Term)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.service_fee_credit_term || "-"}
                    </Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">
                      หลักประกันการปฏิบัติงาน (Performance Bond)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.performance_bond || "-"}
                    </Label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="body-content">
                    <Label className="content-name">
                      มูลค่ารวมข้างต้น (The Total Service Fee above)
                    </Label>
                    <Label className="content-val">{vatDetail || "-"}</Label>
                  </div>
                  <div className="body-content">
                    <Label className="content-name">รายละเอียด (Details)</Label>
                    <Label className="content-val">
                      {requestTemplateDetail.service_fee_payment_detail || "-"}
                    </Label>
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="header-content">ค่าปรับ (Penalty)</div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <div className="body-content">
                  <Label className="content-name">
                    กรณีไม่ส่งมอบงานตามวันเวลาในสัญญา (In case of delay in
                    service performance)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.penalty_delay_service || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    กรณีไม่ส่งมอบงานตามข้อตกลงที่กำหนดไว้ (If work is not
                    delivered as agreed)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.penalty_not_delivered || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    กรณีขาดงาน (In case of absence from work)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.penalty_absence_work || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">กรณีอื่นๆ (Others)</Label>
                  <Label className="content-val">
                    {requestTemplateDetail.penalty_others || "-"}
                  </Label>
                </div>
              </Row>
            </Col>
            <div className="header-content">การบอกเลิกสัญญา (Termination)</div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <div className="body-content">
                  <Label className="content-name">
                    การบอกเลิกสัญญา (Termination)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.termination_detail || "-"}
                  </Label>
                </div>
              </Row>
            </Col>
            <div className="header-content">อื่นๆ (Others)</div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <div className="body-content">
                  <Label className="content-name">
                    ความเป็นเจ้าของกรรมสิทธิ์ในผลสำเร็จของงาน (IP Ownership in
                    the Deliverables)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.ip_ownership_detail || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    การรับประกันผลงาน (Warranty)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.warranty || "-"}
                  </Label>
                </div>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="body-content">
                    <Label className="content-name">
                      อากรแสตมป์ (Stamp Duty)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.stamp_duty || "-"}
                    </Label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="body-content">
                    <Label className="content-name">
                      ค่าธรรมเนียมอื่นๆ (Other Fees)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.other_fee || "-"}
                    </Label>
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="header-content">
              เงื่อนไขอื่น ๆ (หากมี) (Other Conditions)
            </div>
            <Col md={12}>
              <div className="dashed-line"></div>
              <Row>
                <Col md={6}>
                  <div className="body-content">
                    <Label className="content-name">ภาษา (Language)</Label>
                    <Label className="content-val">
                      {requestTemplateDetail.name_language || "-"}
                    </Label>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="body-content">
                    <Label className="content-name">
                      ระดับชั้นความลับ (Confidentiality Level)
                    </Label>
                    <Label className="content-val">
                      {requestTemplateDetail.id_language === 1
                        ? requestTemplateDetail.name_confidential_level_th
                        : requestTemplateDetail.id_language === 2
                        ? requestTemplateDetail.name_confidential_level_en
                        : "-"}
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="body-content">
                  <Label className="content-name">
                    การเปิดเผย/ใช้/ประมวลผลข้อมูลส่วนบุคคลภายใต้เอกสารนี้
                    (Personal data will be disclosed/used/processed under this
                    document)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.share_pdpa === "1" ? "Yes" : "No"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    ความเห็นของ User (User's Comment)
                  </Label>
                  <Label className="content-val">
                    {requestTemplateDetail.user_comment || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    ชื่อนักกฎหมายที่เคยตรวจเอกสารนี้ (Legal team who has handled
                    this work)
                  </Label>
                  <Label className="content-val">
                    {requestDetail.require_handled_by || "-"}
                  </Label>
                </div>
                <div className="body-content">
                  <Label className="content-name">
                    อนุมัติโดย (To be approved by)
                  </Label>
                  <Label className="content-val">
                    {requestDetail.approve_request_by || "-"}
                  </Label>
                </div>
              </Row>
            </Col>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AssignDetailCsa;
