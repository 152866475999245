import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink} from 'reactstrap';
const StepWorkflow = ({step}) => {
    const [idStep, setIdStep] = useState(0);
    const [progressbarStep, setProgressbarStep] = useState(0);
    const arrStep = [
        "Fill out<br />request form",
        "Submitting<br />request",
        "Waiting for<br />acceptance",
        "Reviewing",
        "Waiting for user<br />comment",
        "User approved",
        "Finalizing",
        "Waiting for final<br />approval",
        "Signing",
        "Complete"
    ];

    useEffect(() => {
        setIdStep((step > 1) ? step : 1);
        setProgressbarStep((step - 1) * (100 / 9));
    }, [step]);

    return (
        <React.Fragment>
            <div className="progress-nav mb-4 stepper-block">
                <Nav className="nav-pills progress-bar-tab custom-nav nav d-flex justify-content-center align-items-center position-relative">
                    {[...Array(10)].map((_, index) => (
                        <NavItem
                            key={index}
                            className="flex-grow-1 d-flex flex-column align-items-center stepper-nav-item"
                        >
                            <NavLink
                                id={`pills-gen-info-tab-${index + 1}`}
                                tag="button"
                                className={idStep === index + 1 ? "stepper-nav-link-active" : idStep > index + 1 ? "stepper-nav-link-active" : "stepper-nav-link-inactive"}
                                disabled
                            >
                                {idStep === 10 && index + 1 === 10 ?(
                                    <span className={`circle ${idStep === index + 1 || idStep > index + 1 ? "stepper-span-1-active" : "stepper-span-1-inactive"}`} >
                                        <span className={`circle ${idStep === index + 1 || idStep > index + 1 ? "stepper-span-2-active" : "stepper-span-2-inactive"}`} >
                                            <span className="circle stepper-span-complete" >
                                                <span className="stepper-span-content">
                                                    <i className="mdi mdi-check"></i>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                ):
                                    <span className={`circle ${idStep === index + 1 || idStep > index + 1 ? "stepper-span-1-active" : "stepper-span-1-inactive"}`} >
                                        <span className={`circle ${idStep === index + 1 || idStep > index + 1 ? "stepper-span-2-active" : "stepper-span-2-inactive"}`} >
                                            <span className={`circle ${idStep === index + 1 || idStep > index + 1 ? "stepper-span-3-active" : "stepper-span-3-inactive"}`} >
                                                <span className="stepper-span-content">
                                                    {index + 1}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                }
                            </NavLink>
                            <span
                                className={`${idStep === index + 1 || idStep > index + 1 ? "step-text-active" : "step-text-inactive"}`}
                                dangerouslySetInnerHTML={{
                                __html: arrStep[index].replace(/\n/g, '<br>'),
                                }}
                            >
                            </span>
                        </NavItem>
                    ))}
                    <div className="progress-bar-wrapper">
                        <div className="progress-bar" role="progressbar" style={{ width: `${progressbarStep}%` }}></div>
                    </div>
                </Nav>
            </div>
        </React.Fragment>
    );
};

export default StepWorkflow;