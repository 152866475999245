// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  padding-bottom: 45px;
}

.editor {
  width: 100%;
  min-width: 600px;
  max-width: 1100px;
  min-height: 200px;
  height: 200px;
}

.ql-editor {
  font-size: 16px;
  tab-size: 4;
}

.ql-container {
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-indent-1 {
  margin-left: 20px;
}

.ql-indent-2 {
  margin-left: 40px;
}

.ql-indent-3 {
  margin-left: 60px;
}

.ql-indent-4 {
  margin-left: 80px;
}

.ql-indent-5 {
  margin-left: 100px;
}

.ql-indent-6 {
  margin-left: 120px;
}

.ql-indent-7 {
  margin-left: 140px;
}

.ql-indent-8 {
  margin-left: 160px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/plugins/_quill-editor.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;;EAEI,oCAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".wrapper {\n    padding-bottom: 45px;\n}\n\n.editor {\n    width: 100%;\n    min-width: 600px;\n    max-width: 1100px;\n    min-height: 200px;\n    height: 200px;\n}\n\n.ql-editor {\n    font-size: 16px;\n    tab-size: 4;\n}\n\n.ql-container {\n    font-size: 16px;\n}\n\n.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,\n.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {\n    content: attr(data-value) !important;\n}\n\n.ql-indent-1 {\n    margin-left: 20px;\n}\n\n.ql-indent-2 {\n    margin-left: 40px;\n}\n\n.ql-indent-3 {\n    margin-left: 60px;\n}\n\n.ql-indent-4 {\n    margin-left: 80px;\n}\n\n.ql-indent-5 {\n    margin-left: 100px;\n}\n\n.ql-indent-6 {\n    margin-left: 120px;\n}\n\n.ql-indent-7 {\n    margin-left: 140px;\n}\n\n.ql-indent-8 {\n    margin-left: 160px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
