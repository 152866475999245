import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Label } from 'reactstrap';
import axios from "axios";
import ConfigData from "../../config.json";
import { UserAgentApplication } from 'msal';
import { gapi } from 'gapi-script';
import IconBtnMS from '../../assets/images/icons/btn-icon-ms.png';
import IconBtnGoogle from '../../assets/images/icons/btn-icon-google.png';
import ImgLogin from '../../assets/images/login-img.png';
import Swal from 'sweetalert2';

const Login = () => {
    const history = useHistory();
    let msAuth = null;
    localStorage.clear();
    useEffect(() => {
        const msConfig = {
            auth: {
                clientId: 'b62024cf-cff5-492e-972d-153c0828cc42',
                redirectUri: ConfigData.APP_REDIRECT_LOGIN,
            },
        };
        msAuth = new UserAgentApplication(msConfig);

        gapi.load('auth2', () => {
            gapi.auth2.init({
                client_id: '339150796613-qogfll9tk3s27m8dfenpdb4ajhqrltge.apps.googleusercontent.com',
            });
        });

    }, []);
    

    const OnClickLoginOffice365 = () => {
        msAuth.loginPopup({
            scopes: ['User.Read'],
        }).then((response) => {
            if (response) {
                const respO365 = response.account;
                // console.log("Office365", respO365);
                CheckLoginUser(respO365.userName);
            }
        }).catch(error => {
            // console.error(error);
        });
    };

    const OnClickLoginGoogle = () => {
        gapi.auth2.getAuthInstance().signIn().then((googleUser) => {
            const respGoogle = googleUser.getBasicProfile();
            // console.log("Office365", respGoogle);
            // console.log('ID: ' + respGoogle.getId());
            // console.log('Name: ' + respGoogle.getName());
            // console.log('Email: ' + respGoogle.getEmail());
            CheckLoginUser(respGoogle.getEmail());
        },
        (error) => {
            // console.error('Login failed: ' + error.error);
        });
    };

    const CheckLoginUser = (email) => {
        let url = ConfigData.API_URL_AUTHEN + "login";
        let data = {
            user_name: email,
            app_key: ConfigData.APP_KEY,
            app_secret: ConfigData.APP_SECRET
        };
        axios.post(url, data, { responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                if(respData.data.have_domain === true){
                    if(respData.data.uid > 0){
                        localStorage.setItem("emd", btoa(respData.data.email));
                        localStorage.setItem("nmd", btoa(respData.data.name + " " + respData.data.surname));
                        localStorage.setItem("gid", btoa(respData.data.gid));
                        localStorage.setItem("tkd", respData.data.token);
                        LoadMyMenu(respData.data.email, respData.data.gid);
                    }else{
                        localStorage.setItem("tkd", respData.data.token);
                        history.push("/register/" + btoa(email));
                    }
                }else{
                    console.log("Don't have domain");
                    Swal.fire({
                        icon: 'warning',
                        title: 'Unable to Log in',
                        text: "You can log in using your internal email address. or double check the authenticity of your email.",
                        confirmButtonColor: '#0778FF',
                    });
                }
            }else{
                // setError("Username or Password Invalid.");
            }
        })
        .catch(error => {
            // setError("Username or Password Invalid.");
            console.log(error);
        });
    };

    const LoadMyMenu = (email, gid) => {
        let url = ConfigData.API_URL + "screen/email/" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                localStorage.setItem("mnd", btoa(JSON.stringify(respData.data)));
                if(gid === 1){
                    // history.push("/request_form");
                    history.push("/requests");
                }else{
                    // history.push("/dashboard");
                    history.push("/requests");
                }
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="login">
                <div className="login-container">
                    <div className="login-left-content">
                        <div className="login-content">
                            <img src={ImgLogin} className="login-left-img" alt="" />
                        </div>
                    </div>
                    <div className="login-right-content">
                        <div className="login-content">
                            <div className="login-title">
                                Welcome to Legal Request Form
                                <br />
                                Log in to your account
                            </div>
                            
                            <Button className="login-btn" type="button" onClick={OnClickLoginGoogle}>
                                <img src={IconBtnGoogle} alt="" />
                                <Label>Login with Google</Label>
                            </Button> 
                            <Button className="login-btn" type="button" onClick={OnClickLoginOffice365}>
                                <img src={IconBtnMS} alt="" />
                                <Label>Login with Microsoft 365</Label>
                            </Button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;