import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button, Col, Container, Form, FormFeedback, Row } from 'reactstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import StepWorkflow from '../../components/common/step-workflow';
import SingleUploadfile from '../../components/common/single-uploadfile';
import RequestHistory from '../../components/request/request-history';
import ConfigData from "../../config.json";
import { getFilterOldFileName, setFormDataUploadFile, setStringFileNameToArray } from '../../components/utils/format-value';
import { IconAlertSuccess } from '../../components/common/popup-alert';
import Swal from 'sweetalert2';
import MultipleDownloadfile from '../../components/common/multiple-downloadfile';

const SignedContract = () => {
    const { id } = useParams();
    const history = useHistory();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [fileSignedDoc, setFileSignedDoc] = useState([]);
    const [fileMemo, setFileMemo] = useState([]);
    const [fileFinalContract, setFileFinalContract] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [dataRequest, setDataRequest] = useState({
        "id": 0,
        "id_workflow_status": 0,
        "request_no": "",
        "contract_no": "",
        "final_doc": "",
        "memo_doc": "",
        "request_by": "",
        "signed_doc": "",
        "send_to": ""
    });

    useEffect(() => {
        changeTitlePage("All Request");
        let strId = "0";
        try{
            strId = atob(id);
        }catch(error){
            strId = "0";
        }
        LoadUserCanDoReq(strId);
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataRequest.id || 0,
            id_workflow_status: dataRequest.id_workflow_status || 0,
            request_no: dataRequest.request_no || "",
            contract_no: dataRequest.contract_no || "",
            request_by: dataRequest.request_by || "",
            signed_doc: dataRequest.signed_doc || "",
            send_to: dataRequest.send_to || "",
        },
        validationSchema: Yup.object({
            signed_doc: Yup.string().required("กรุณาแนบไฟล์เอกสารที่ลงนามเรียบร้อยแล้ว/Please attach the signed document"),
        }),
        onSubmit: (values) => {
            SaveSignedDoc(values);
        }
    });

    const OnSetFileSignedDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setFileSignedDoc(file);
        validation.handleChange('signed_doc')(fileName);
    };

    const OnClickBack = () => {
        // history.push("/dashboard");
        history.push("/requests");
    };

    const LoadDataRequest = (id) => {
        let url = ConfigData.API_URL + "request/" + id ;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                dataRequest.id = respData.data[0].id;
                dataRequest.id_workflow_status = respData.data[0].id_workflow_status;
                dataRequest.request_no = respData.data[0].request_no;
                dataRequest.contract_no = respData.data[0].contract_no;
                dataRequest.final_doc = respData.data[0].final_doc;
                dataRequest.memo_doc = respData.data[0].memo_doc;
                dataRequest.request_by = respData.data[0].request_by;
                dataRequest.signed_doc = respData.data[0].signed_doc;
                dataRequest.send_to = respData.data[0].request_by;
                setFileSignedDoc(setStringFileNameToArray(respData.data[0].signed_doc));
                setFileFinalContract([{"name": respData.data[0].final_doc}]);
                setFileMemo([{"name": respData.data[0].memo_doc}]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataHistory = (idReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" }) 
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataHistory(respData.data);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadUserCanDoReq = (id) => {
        let userDo = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let url = new URL(ConfigData.API_URL + "request/user-can-do");
        url.searchParams.set("email", userDo);
        url.searchParams.set("id-request", id);
        url.searchParams.set("id-workflow", "21"); 
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrUserDo = respData.data[0].user_do_request.split(",");
                if(arrUserDo.find((e) => e === userDo) !== undefined){
                    LoadDataRequest(id);
                    LoadDataHistory(id);
                }else{
                    history.push("/summary-request/" + btoa(id));
                }
            }else{
                // history.push("/dashboard");
                history.push("/requests");
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const SaveSignedDoc = (values) => {
        if(values.id > 0){
            let isSaveSucess = true;
            (async () => {
                let isSaveSignedDoc = await UpdateSignedDoc(values);
                isSaveSucess = (isSaveSignedDoc === true && isSaveSucess === true) ? true : false;
                let isSaveReqWorkflowStatus = await AddWorkFlowStaus(values);
                isSaveSucess = (isSaveReqWorkflowStatus === true && isSaveSucess === true) ? true : false;
                if(isSaveSucess === true){
                    SendEmail(values);
                    Swal.fire({
                        iconHtml: IconAlertSuccess,
                        title: "เอกสารถูกจัดทำสำเร็จแล้ว\nThe document\nhas been completed.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    }).then(() => {
                        history.push("/summary-request/" + btoa(values.id));
                    });
                }
            })();
        }
    };

    const UpdateSignedDoc = async (data) => {
        const url = ConfigData.API_URL + "request/signed-doc/" + data.id;
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let formData = new FormData();
        formData.append("request_no", data.request_no);
        formData.append("n_signed_doc", getFilterOldFileName(fileSignedDoc));
        formData = setFormDataUploadFile(formData, "f_signed_doc", fileSignedDoc);
        try{
            const resp = await axios.put(url, formData, { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const AddWorkFlowStaus = async (dataReq) => {
        const url = ConfigData.API_URL + "request-workflow/step";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try{
            let formData = new FormData();
            formData.append("id_request", dataReq.id);
            formData.append("id_workflow_status", "21");
            formData.append("do_by", userId);
            formData.append("send_to", dataReq.send_to);
            const resp = await axios.post(url, formData, { headers: { 
                            'Authorization' : token,
                            'User': userId,
                        }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const SendEmail = (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 20,
            "request_id": data.id,
        };
        axios.post(url, dataReq, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            // console.log(resp.data);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="signed-contract">
                    <Row>
                        <Col lg={12}>
                            <p className="sc-title">Request Detail {"> " + dataRequest.request_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <StepWorkflow step={9} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <div className="sc-msg-head">
                                <p className="sc-msg-head-title">
                                    <i className="mdi mdi-check"></i>
                                    ผู้อนุมัติขั้นสุดท้ายทุกท่านได้อนุมัติร่างเอกสารสุดท้ายแล้ว
                                </p>
                                <p className="sc-msg-head-title2">
                                    (All final approvers approved final document)
                                </p>
                                <p className="sc-msg-head-detail">
                                    อัปโหลดเอกสารที่ได้รับการลงนามแล้ว เพื่อให้คำขอเสร็จสมบูรณ์ (Please upload the signed document to complete this request.)
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <div className="sc-form">
                                <Form className="needs-validation"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row className="sc-form-title">
                                        <Col lg={12}>
                                            <p>เอกสารที่ถูกลงนามแล้ว (Signed Document)</p>
                                        </Col>
                                    </Row>
                                    <Row className="sc-form-detail">
                                        <Col lg={12}>
                                            <p className="sc-form-detail-contract-label">Contract No.</p>
                                            <p className="sc-form-detail-contract-detail">{(dataRequest.contract_no !== "") ? dataRequest.contract_no : "-"}</p>
                                            <p className="sc-form-detail-final-contract-label">ร่างเอกสารสุดท้ายและเอกสารแนบ (Finalized Document and Attachment)</p>
                                            <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileFinalContract} />
                                            <p className="sc-form-detail-memo-label">Memo สำหรับเสนอลงนาม (Memo for Signing Procedure)</p>
                                            <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileMemo} />
                                        </Col>
                                    </Row>
                                    <Row className="sc-form-workarea">
                                        <Col lg={12}>
                                            <p className="sc-form-workarea-file-label">อัปโหลดเอกสารที่ลงนามเรียบร้อยแล้ว (Upload Signed Document)<span className="msg-require">*</span></p>
                                            <SingleUploadfile inputID="userFinalDoc" folder={validation.values.request_no} dataFile={fileSignedDoc} setDataFile={OnSetFileSignedDoc} />
                                            { validation.touched.signed_doc && validation.errors.signed_doc ? 
                                                ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.signed_doc}</FormFeedback> ) : false 
                                            }
                                        </Col>
                                    </Row>
                                    <div className="page-footer">
                                        <div className="layout-width-footer">
                                            <div className="navbar-footer2">
                                                <Button type="button" className="sc-form-btn-back" onClick={(e) => { OnClickBack(); }}>
                                                    Back
                                                </Button>
                                                <Button type="submit" color='primary' className="sc-form-btn-submit">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <div className="sc-history">
                                <p className="sc-history-title">History</p>
                                <RequestHistory data={dataHistory} column="do_date" requestNo={dataRequest.request_no} />
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default SignedContract;