import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import MenuVertical from "./menus/menu-vertical";
import { Container } from "reactstrap";

// import logoSm from "../assets/images/logo-sm.png";
// import logoLight from "../assets/images/logo-light.png";
import logo from "../assets/images/btsg_legal_logo.png";

const Sidebar = (props) => {
    useEffect(() => {
        var verticalOverlay = document.getElementsByClassName("vertical-overlay");
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener("click", function () {
                document.body.classList.remove("vertical-sidebar-enable");
            });
        }
    });

    // const addEventListenerOnSmHoverMenu = () => {
    //     if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
    //         document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
    //     } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
    //         document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    //     } else {
    //         document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    //     }
    // };

    return (
        <React.Fragment>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <Link to="/requests" className="logo">
                        <span className="logo-lg">
                            {/* <img src={logo} alt="" height="32" /> */}
                            <img src={logo} alt="" height="38" />
                        </span>
                    </Link>
                </div>
                <SimpleBar id="scrollbar" className="h-100">
                    <Container fluid>
                        <ul className="navbar-nav" id="navbar-nav">
                            <MenuVertical />
                        </ul>
                    </Container>
                </SimpleBar>
                <div className="sidebar-background"></div>
            </div>
            <div className="vertical-overlay"></div>
        </React.Fragment>
    );
};

export default Sidebar;
