import React, { useEffect, useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import "../../assets/scss/pages/_assign-work.scss";
import { useHistory } from "react-router-dom";
import ConfigData from "../../config.json";
import axios from "axios";
import Swal from "sweetalert2";
import { IconAlertReject, IconAlertSuccess } from "../common/popup-alert";

const ApproveReject = ({ requestDetail }) => {
  const accessToken = localStorage.getItem("tkd");
  const userId = !localStorage.getItem("emd") ? "" : atob(localStorage.getItem("emd"));
  const history = useHistory();
  const [requestWorkflowDetail, setRequestWorkflowDetail] = useState([]);

  useEffect(() => {
    if (Object.keys(requestDetail).length > 0) {
      LoadRequestWorkflowDetail(requestDetail.id, 12);
    }
  }, [requestDetail]);

  const showLoading = (isShow) => {
    if (isShow) {
      Swal.fire({
        icon: "info",
        title: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  };

  const handleClickCancel = () => {
    showLoading(true);
    let workflowId = 14; //reject cancel request by user
    LoadRequestWorkflowDetail(requestDetail.id, 7);

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: requestDetail.accept_request_by,
      cc_to: "",
      comment: "",
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertReject,
          title:
            "Cancellation request is not approved Send it back to the legal team.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 17,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: [requestDetail.accept_request_by],
          };

          SendNotiEmail(sendEmailReq);
          // history.push("/");
          history.push("/requests");
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to cancel reject request.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  const handleClickApprove = () => {
    showLoading(true);
    let workflowId = 13; // approve cancel request by user

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: requestDetail.accept_request_by,
      cc_to: "",
      comment: "",
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertSuccess,
          title: "The request was deleted successfully.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 16,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: [requestDetail.accept_request_by],
          };

          SendNotiEmail(sendEmailReq);
          // history.push("/");
          history.push("/requests");
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to approve reject request.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  //#region Call APIs

  const LoadRequestWorkflowDetail = (requestId, workflowStatus) => {
    let url = ConfigData.API_URL + "request-workflow/search?idRequest=" + requestId + "&idWorkflowStatus=" + workflowStatus;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        let respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestWorkflowDetail(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ChangeWorkflowStatus = async (dataReq) => {
    let formData = new FormData();
    formData.append("request_no", dataReq.request_no);
    formData.append("id_request", dataReq.id_request);
    formData.append("id_workflow_status", dataReq.id_workflow_status);
    formData.append("do_by", dataReq.do_by);
    formData.append("send_to", dataReq.send_to);
    formData.append("cc_to", dataReq.cc_to);
    formData.append("comment", dataReq.comment);
    formData.append("is_last", dataReq.is_last);
    let url = ConfigData.API_URL + "request-workflow/step";
    try {
      let resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
          "User": formData.get("do_by"),
        },
        responseType: "json",
      });
      let respData = resp.data;
      return respData.is_sucess;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const SendNotiEmail = async (emailReq) => {
    const jsonReq = JSON.stringify(emailReq);
    let url = ConfigData.API_URL + "email/send";
    try {
      let resp = await axios.post(url, jsonReq, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      });
      const respData = resp.data;
      return respData.is_sucess;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  //#endregion Call APIs

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} className="mx-auto">
          <div className="cancel-request-container">
            <div className="header-content">
              <div className="warning-content">
                <i className="mdi mdi-alert-box warning-icon"></i>
                Legal team needs to cancel the request!
              </div>
            </div>
            <div className="body-content">
              <div className="content-name">Reason to cancel</div>
              <Label className="content-val">
                {requestWorkflowDetail.comment || "-"}
              </Label>
            </div>
            <Row>
              <Col md={12}>
                <div className="body-content float-end">
                  <div className="d-flex align-items-center">
                    <div className="btn-group">
                      <Button
                        type="button"
                        className="float-end btn-base btn-cancel"
                        onClick={handleClickCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        className="float-end btn-base btn-approve-reject"
                        onClick={handleClickApprove}
                      >
                        Approve Reject
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApproveReject;
