import React, { createContext, useReducer } from 'react';

const initialState = {
    titlePage: "",
    colorName: ""
};
  
const changeGlobalDataReducer = (state, action) => {
    if(action.type === "CHANGE_TITLE_PAGE"){
        return {
            ...state,
            titlePage: action.payload
        };
    }else if(action.type === "CHANGE_NAME"){
        return {
            ...state,
            colorName: action.payload
        };
    }
};

export const GolbalDataContext = createContext({});

export const GolbalDataProvider = ({ children }) => {
    const [dataState, dataDispatch] = useReducer(changeGlobalDataReducer, initialState);
    const { titlePage, colorName } = dataState;
    const changeTitlePage = payload => dataDispatch({ type: "CHANGE_TITLE_PAGE", payload});
    const changeColorName = payload => dataDispatch({ type: "CHANGE_NAME", payload});

    return(
        <GolbalDataContext.Provider value={{ titlePage, changeTitlePage, colorName, changeColorName }}>
            {children}
        </GolbalDataContext.Provider>
    );
};