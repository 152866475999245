const ConvertEmployeeId = (employeeID, digit) => {
    let modifyEmployeeId = '';
    for (let i = 0; i < employeeID.length; i++) {
        modifyEmployeeId += employeeID[i];
        if ((i + 1) % digit === 0 && i !== employeeID.length - 1) {
            modifyEmployeeId += '-';
        }
    }
    return modifyEmployeeId;
};

const ConvertDataMenuForShow = (dataMenus) => {
    let arrMenu = [];
    const arrModule = dataMenus.filter((e) => e.id_sub_module === 0 && e.is_show === "1");
    for (let i = 0; i < arrModule.length; i++) {
        let objMenu = arrModule[i];
        let arrSubModule = dataMenus.filter((e) => e.id_module === arrModule[i].id_module && e.id_sub_module > 0 && e.is_show === "1");
        objMenu.subItems = arrSubModule;
        arrMenu.push(objMenu);
    }
    return arrMenu;
};

const ConvertPathLocalToMenu = (urlLocal, isUseMenu) => {
    let localPathName = urlLocal.replace("/", "");
    let arrLocalPathName = localPathName.split("/");
    // if(isUseMenu === true){
    //     localPathName = (arrLocalPathName.length > 1) ? "/" + arrLocalPathName[0] : "/" + localPathName;
    // }else{
    //     localPathName = (arrLocalPathName.length > 1) ? "/" + arrLocalPathName[0] + "/param" : "/" + localPathName;
    // }
    localPathName = (arrLocalPathName.length > 1) ? "/" + arrLocalPathName[0] + "/param" : "/" + localPathName;
    return localPathName;
};

const setUrlMenu = (urlPath, urlParam) => {
    if(urlPath !== "" && urlPath !== null && typeof(urlPath) !== "undefined") {
        if(urlParam !== ""){
            return urlPath.replace("/param", urlParam);
        }
        return urlPath;
    }
    return "/#";
};

const setStringFileNameToArray = (strFileNames) => {
    const arrData = [];
    const arrFileNames = strFileNames.split(",");
    for(let i = 0; i < arrFileNames.length; i++){
        if(arrFileNames[i] !== "" && arrFileNames[i] !== null){
            let objData = {
                name: arrFileNames[i],
                file: null
            }
            arrData.push(objData);
        }
    }
    return arrData;
};

const setShowFileName = (strFileName) => {
    const arrFileName = strFileName.split("__");
    return (arrFileName.length > 1) ? arrFileName[1] : strFileName;
};

const getFilterOldFileName = (arrData) => {
    let strFileName = "";
    for(let i = 0; i < arrData.length; i++){
        if(arrData[i].file === null){
            if(strFileName !== ""){
                strFileName += ",";
            }
            strFileName += arrData[i].name;
        }
    }
    return strFileName;
};

const setFormDataUploadFile = (formData, formName, arrData) => {
    for(let i = 0; i < arrData.length; i++){
        if(arrData[i].file !== null && typeof(arrData[i].file) !== "undefined"){
            // arrFile.push(arrData[i].file);
            formData.append(formName, arrData[i].file);
        }
    }
    return formData;
};

const setGroupAndSortByDate =(data, column) => {
    const groupedData = data.reduce((groups, item) => {
        const date = new Date(item[column]);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const key = year + "-" + month + "-" + day + "T00:00:00";

        if (!groups.has(key)) {
            groups.set(key, []);
        }
        item.show = false;
        groups.get(key).push(item);
        
        return groups;
    }, new Map());
    const arrGroup = Array.from(groupedData, ([key, value]) => ({ key, value }));
    for (const key in arrGroup) {
        arrGroup[key].value.sort((a, b) => {
            // return new Date(a[column]) - new Date(b[column]);
            let dateA = new Date(a[column]);
            let dateB = new Date(b[column]);
            const timeA = dateA.getHours() * 10000 + dateA.getMinutes() * 100 + dateA.getSeconds();
            const timeB = dateB.getHours() * 10000 + dateB.getMinutes() * 100 + dateB.getSeconds();
            return timeB - timeA;
        });
    }
    return arrGroup;
}

const ConvertFirstStringUpperCase = (value) => {
    return value.substr(0, 1).toUpperCase() + value.substr(1);
};

export { ConvertEmployeeId, ConvertDataMenuForShow, ConvertPathLocalToMenu, setUrlMenu, setStringFileNameToArray, setShowFileName, getFilterOldFileName, setFormDataUploadFile, setGroupAndSortByDate, ConvertFirstStringUpperCase };