import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Container, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { GolbalDataContext } from '../../components/store/global-data-provider';
import StepWorkflow from "../../components/common/step-workflow";
import RequestUserInformation from '../../components/request/request_user_information';
import ChooseRequestForm from '../../components/request/choose_request_form';
import ConfigData from '../../config.json';
import axios from 'axios';

const Request = () => {
  const { changeTitlePage } = useContext(GolbalDataContext);
  const [activeTab, setactiveTab] = useState(1);
  const [formType, setFormType] = useState({});
  const [formTemp, setFormTemp] = useState({});
  const [activeStep, setActiveStep] = useState(1); // Track the active step

  useEffect(() => {
    changeTitlePage('Create Request');
    if (activeStep === 2) {
        LoadFormTemp(formType);
    }
  }, [formType]);

  const LoadFormTemp = (dataRequestType) => {
    let url = ConfigData.API_URL + "form-temp/search-form-temp-id?id_request=" + dataRequestType.id_request_type + "&id_sub_request=" + dataRequestType.id_sub_request_type + "&id_sub_division_request=" + dataRequestType.id_sub_division_request_type + "&status=1";
    let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setFormTemp(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div>
                <StepWorkflow step={1} />
                <TabContent activeTab={activeTab}>
                    {/* Conditional rendering based on active step */}
                    {activeStep === 1 && (
                        <ChooseRequestForm
                            typeCallback={(formData) => {
                                setFormType(formData);
                            }}
                            nextStep={() => setActiveStep(2)}
                        />
                    )}
                    {activeStep === 2 && <RequestUserInformation dataFormType={formTemp} />}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Request;