import React from 'react';
import axios from "axios";
import ConfigData from "../../config.json";
import { Col, Row } from 'reactstrap';
import { setShowFileName } from '../utils/format-value';

const MultipleDownloadfile = ({folder, dataFile}) => {

    const OnClickOpenFile = (fileName, fileDoc) => {
        let url = new URL(ConfigData.API_URL + "file/read");
        url.searchParams.set("filename", (fileDoc !== "" && fileDoc !== null && fileDoc !== undefined) ? fileDoc : fileName);
        url.searchParams.set("folder", folder);
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                window.open(respData.data.url);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="form-download-multi">
                <ul className="form-download-multi-ul">
                    {dataFile.map((val, key) => {
                        return(
                            <li className="form-download-multi-li" key={"userFinalDoc" + key}>
                                <div className="form-download-multi-content" >
                                    <span>
                                        <i className="mdi mdi-file"></i>
                                        <label>{setShowFileName(val.name)}</label>
                                    </span>
                                    <i className="mdi mdi-download i-download" onClick={(e) => { OnClickOpenFile(val.name, val.document) }}></i>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </React.Fragment>
    );
};
 
export default MultipleDownloadfile;