import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Select from "react-select";
import ConfigData from "../../config.json";
import { Row, Col, Form, FormFeedback, FormGroup, Input, Label, Button } from 'reactstrap';

const Register = () => {
    const history = useHistory();
    const { email } = useParams();
    const [dataBU, setDataBU] = useState([]);
    const [selectBU, setSelectBU] = useState("");
    const [objUser, setObjUser] = useState({
        id_business_unit: "",
        id_user_group: 0,
        email: "",
        name: "",
        surname: "",
        department: "",
        domain: ""
    });

    useEffect(() => {
        const strEmail = atob(email);
        const arrDomain = strEmail.split("@");
        const strDomain = (arrDomain.length > 1) ? arrDomain[1] : "-";
        // console.log(strEmail, strDomain);
        LoadDataBusinessUnit(strDomain);
        GetDataUserGroup(strEmail, strDomain);
    }, [email]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id_business_unit: objUser.id_business_unit || "",
            id_user_group: objUser.id_user_group || 0,
            email: objUser.email || "",
            name: objUser.name || "",
            surname: objUser.surname || "",
            department: objUser.department || "",
        },
        validationSchema: Yup.object({
            id_business_unit: Yup.string().required("Please select a Business Unit"),
            email: Yup.string().required("Please enter a Username"),
            name: Yup.string().required("Please enter a Firstname"),
            surname: Yup.string().required("Please enter a Lastname"),
            department: Yup.string().required("Please enter a Department Name"),
        }),
        onSubmit: (values) => {
            values.id_business_unit = (values.id_business_unit !== "") ? parseInt(values.id_business_unit) : 0;
            AddUser(values);
        }
    });

    const OnClickBack = () => {
        history.push("/login");
    };

    const LoadDataBusinessUnit = (domain) => {
        let url = ConfigData.API_URL + "business-unit/search?status=1&domain=" + domain;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name_en,
                        value: e.id.toString()
                    };
                });
                setDataBU(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadMyMenu = (email, gid) => {
        let url = ConfigData.API_URL + "screen/email/" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                localStorage.setItem("mnd", btoa(JSON.stringify(respData.data)));
                if(gid === 1){
                    // history.push("/request_form");
                    history.push("/requests");
                }else{
                    // history.push("/dashboard");
                    history.push("/requests");
                }
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const GetDataUserGroup = (email, domain) => {
        let url = ConfigData.API_URL + "user-group/email-user/" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                if(domain === "btsgroup.co.th"){
                    GetDataEmployeeBTSG(email, domain, respData.data[0].id);
                }else{
                    setObjUser({
                        id_business_unit: "",
                        id_user_group: respData.data[0].id,
                        email: email,
                        name: "",
                        surname: "",
                        department: "",
                        domain: domain
                    });
                }
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const GetDataEmployeeBTSG = (email, domain, idUserGroup) => {
        let urlAuthen = ConfigData.HRIS_API_URL_AUTHEN + "login-b2b";
        let dataAuthen = {
            user_name: ConfigData.HRIS_APP_USERNAME,
            password: ConfigData.HRIS_APP_PASS,
            app_key: ConfigData.HRIS_APP_KEY,
            app_secret: ConfigData.HRIS_APP_SECRET
        };
        axios.post(urlAuthen, dataAuthen, { responseType: "json" })
        .then(resp => {
            let respToken = resp.data;
            if(respToken.is_sucess === true){
                let url = ConfigData.HRIS_API_URL + "employee-general/search?status=1&officeEmail=" + email;
                let token = respToken.data.token;
                axios.get(url, { headers: { 
                    'Authorization' : token ,
                }, responseType: "json" })
                .then(resp => {
                    let respData = resp.data;
                    let objData = {
                        id_business_unit: "",
                        id_user_group: idUserGroup,
                        email: email,
                        name: "",
                        surname: "",
                        department: "",
                        domain: domain
                    };
                    if(respData.is_sucess === true && respData.data.length > 0){
                        objData.name = respData.data[0].name_en;
                        objData.surname = respData.data[0].surname_en;
                        objData.department = respData.data[0].name_department;
                    }
                    setObjUser(objData);
                })
                .catch(error => {
                    console.log(error);
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const AddUser = (data) => {
        let url = ConfigData.API_URL + "user/";
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = data.email;
        axios.post(url, data, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                localStorage.setItem("emd", btoa(respData.data[0].email));
                localStorage.setItem("nmd", btoa(respData.data[0].name + " " + respData.data[0].surname));
                localStorage.setItem("gid", btoa(respData.data[0].id_user_group));
                LoadMyMenu(respData.data[0].email, respData.data[0].id_user_group);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="register">
                <Row className="register-header align-items-center">
                    <Col md={12}>
                        <i className="ri-arrow-left-line register-header-back" onClick={OnClickBack}></i>
                        <span className="register-header-title"> 
                            Create Account
                        </span>
                    </Col>
                </Row>
                <Row className="register-detail justify-content-center">
                    <Col sm={12} md={7} lg={5}>
                        <p className="register-detail-title">It's quick and easy.</p>
                        <Form className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label className="text-muted" htmlFor="txtEmail">Username</Label>
                                        <Input id="txtEmail"
                                            name="email"
                                            placeholder="Email"
                                            className="from-control"
                                            disabled={true}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email || ""}
                                            invalid={ validation.touched.email && validation.errors.email ? true : false }
                                        />
                                        { validation.touched.email && validation.errors.email ? 
                                            ( <FormFeedback type="invalid">{validation.errors.email}</FormFeedback> ) : false 
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label className="text-muted" htmlFor="txtName">Firstname<span className="msg-require">*</span></Label>
                                        <Input id="txtName" 
                                            name="name"
                                            placeholder="Name"
                                            className="from-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.name || ""}
                                            invalid={ validation.touched.name && validation.errors.name ? true : false }
                                        />
                                        { validation.touched.name && validation.errors.name ? 
                                            ( <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> ) : false 
                                        }
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label className="text-muted" htmlFor="txtSurname">Lastname<span className="msg-require">*</span></Label>
                                        <Input id="txtSurname" 
                                            name="surname"
                                            placeholder="Surname"
                                            className="from-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.surname || ""}
                                            invalid={ validation.touched.surname && validation.errors.surname ? true : false }    
                                        />
                                        { validation.touched.surname && validation.errors.surname ? 
                                            ( <FormFeedback type="invalid">{validation.errors.surname}</FormFeedback> ) : false 
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label className="text-muted" htmlFor="ddlBusinessUnit">Business Unit<span className="msg-require">*</span></Label>
                                        <Select id="ddlBusinessUnit"
                                            name="id_business_unit"
                                            value={selectBU}
                                            onChange={(selected) => {
                                                setSelectBU(selected);
                                                validation.handleChange('id_business_unit')((selected !== null) ? selected.value : "");
                                            }}
                                            options={dataBU}
                                            isClearable={true}
                                            isSearchable={true}
                                            invalid={ validation.touched.id_business_unit && validation.errors.id_business_unit ? true : false }
                                        />
                                        { validation.touched.id_business_unit && validation.errors.id_business_unit ? 
                                            ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.id_business_unit}</FormFeedback> ) : false 
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label className="text-muted" htmlFor="txtDepartment">Department Name<span className="msg-require">*</span></Label>
                                        <Input id="txtDepartment" 
                                            name="department"
                                            placeholder="Department"
                                            className="from-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.department || ""}
                                            invalid={ validation.touched.department && validation.errors.department ? true : false }    
                                        />
                                        { validation.touched.department && validation.errors.department ? 
                                            ( <FormFeedback type="invalid">{validation.errors.department}</FormFeedback> ) : false 
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="text-center mb-4">
                                    <Button color="primary" type="submit" className="register-detail-save">
                                        Sign up
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Register;