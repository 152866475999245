import React from "react";
import RouteIndex from "./routes/route-index";
import './assets/scss/themes.scss';

const App = () => {
  return (
    <React.Fragment>
      <RouteIndex />
    </React.Fragment>
  );
}

export default App;