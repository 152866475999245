import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import "../../assets/scss/pages/_assign-work.scss";
import MultipleUploadfile from "../../components/common/multiple-uploadfile";
import Editor from "../../components/common/editor";
import ConfigData from "../../config.json";
import axios from "axios";
import StepWorkflow from "../../components/common/step-workflow";
import AssignDetailCsa from "../../components/assign/assign-detail-csa";
import ApproveReject from "../../components/assign/approve-reject";
import RequestHistory from "../../components/request/request-history";
import { getFilterOldFileName, setFormDataUploadFile } from "../../components/utils/format-value";
import { GolbalDataContext } from "../../components/store/global-data-provider";
import { useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { IconAlertReject, IconAlertSubmitCancel, IconAlertSuccess } from "../../components/common/popup-alert";
import { useFormik } from "formik";
import * as Yup from "yup";

const ReviewComment = () => {
  const accessToken = localStorage.getItem("tkd");
  const userId = !localStorage.getItem("emd") ? "" : atob(localStorage.getItem("emd"));
  const userGroup = !localStorage.getItem("gid") ? "" : atob(localStorage.getItem("gid"));
  const { id } = useParams();
  const history = useHistory();
  const { changeTitlePage } = useContext(GolbalDataContext);
  const [requestDetail, setRequestDetail] = useState([]);
  const [requestTemplateDetail, setRequestTemplateDetail] = useState([]);
  const [comment, setComment] = useState("");
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [sendCommentTo, setSendCommentTo] = useState("");
  const [sendCcToValid, setSendCcToValid] = useState(false);
  const [sendCcToValue, setSendCcToValue] = useState("");
  const [sendCcTo, setSendCcTo] = useState([]);
  const [requestHistory, setRequestHistory] = useState([]);
  const [isCancelRequestModal, setIsCancelRequestModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelAttachment, setCancelAttachment] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [submitState, setSubmitState] = useState("");

  useEffect(() => {
    let strId = "0";
    try {
      strId = atob(id);
    } catch (error) {
      strId = "0";
    }
    showLoading(true);
    changeTitlePage("All Request");
    LoadUserCanDoReq(strId);
    showLoading(false);
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id_comment: "",
    },
    validationSchema: Yup.object({
      id_comment: Yup.string().required("กรุณาระบุความคิดเห็น"),
    }),
    onSubmit: () => {
      switch (submitState) {
        case "return": {
            handleClickReturn();
          } break;
        case "approve": {
            handleClickApprove();
          } break;
        case "submit": {
            handleClickSubmit();
          } break;
        default: {
            handleClickSubmit();
          } break;
      }
      setSubmitState("");
    },
  });

  const validationCancel = useFormik({
    enableReinitialize: true,
    initialValues: {
      id_cancel_reason: "",
    },
    validationSchema: Yup.object({
      id_cancel_reason: Yup.string().required("กรุณาระบุความคิดเห็น"),
    }),
    onSubmit: () => {
      handleClickCancelSubmit();
    },
  });

  const showLoading = (isShow) => {
    if (isShow) {
      Swal.fire({
        icon: "info",
        title: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  };

  const SwitchStepWorkflow = () => {
    switch (requestDetail.id_workflow_status) {
      case 9:
      case 11:
      case 13:
      case 14:
        return <StepWorkflow step={5} />;
      default:
        return <StepWorkflow step={4} />;
    }
  };

  const SwitchRequestDetail = () => {
    switch (requestDetail.table_name?.toLowerCase()) {
      case "csa":
        return (
          <AssignDetailCsa
            requestDetail={requestDetail}
            requestTemplateDetail={requestTemplateDetail}
          />
        );
      default:
        return <div></div>;
    }
  };

  const handleChangeComment = (e) => {
    setComment(e);
    validation.handleChange("id_comment")(e);
    setIsSaveEnabled(true);
  };

  const onSetAttachmentFile = (file) => {
    setAttachmentFile(file);
  };

  function isValidEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  }

  const handleChangeSendCcTo = (e) => {
    isValidEmail(e.target.value) ? setSendCcToValid(true) : setSendCcToValid(false);
    setSendCcToValue(e.target.value);
  };

  const handleClickAddSendCcTo = () => {
    if (sendCcToValue && sendCcToValid) {
      setSendCcTo([...sendCcTo, sendCcToValue]);
      setSendCcToValue("");
    }
  };

  const handleClickRemoveSendCcTo = (e) => {
    let currentSendCcTo = sendCcTo.filter((item) => {
      return item !== e;
    });
    setSendCcTo(currentSendCcTo);
  };

  const GetSendCcTo = () => {
    let currentCC = sendCcTo;
    if (!sendCcTo.includes(requestDetail.approve_request_by)) {
      currentCC = [requestDetail.approve_request_by, ...sendCcTo];
    }
    return currentCC;
  };

  const handleClickBack = () => {
    history.push("/requests");
  };

  const handleClickCancelRequest = () => {
    setIsCancelRequestModal(true);
  };

  //#region User

  const handleClickReturn = () => {
    showLoading(true);
    let workflowId = 9; //comment by user

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: sendCommentTo,
      cc_to: sendCcTo.map((item) => item).join(","),
      comment: comment,
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest, attachmentFile);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertReject,
          title: "Sending back to the legal.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 9,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: sendCommentTo.trim().split(","),
            cc: sendCcTo,
          };

          SendNotiEmail(sendEmailReq);
          history.push("/requests");
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to send back to the legal.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  const handleClickApprove = () => {
    showLoading(true);
    let workflowId = 10; //approve review & comment request

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: userId,
      cc_to: sendCcTo.map((item) => item).join(","),
      comment: comment,
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest, attachmentFile);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertSuccess,
          title: "Approved the request continue to next step.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 11,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: [userId],
            cc: sendCcTo,
          };

          SendNotiEmail(sendEmailReq);
          history.push("/memo/" + id);
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to approve the request.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  //#endregion User

  //#region Legal

  const handleClickSubmit = () => {
    showLoading(true);
    let workflowId = 8; //reviewing by legal

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: sendCommentTo,
      cc_to: sendCcTo.map((item) => item).join(","),
      comment: comment,
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest, attachmentFile);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertSuccess,
          title: "Sending the request for users to review.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 8,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: sendCommentTo.trim().split(","),
            cc: GetSendCcTo(),
          };

          SendNotiEmail(sendEmailReq);
          history.push("/requests");
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to send request for users to review.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  //#endregion Legal

  //#region Cancel

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
    validationCancel.handleChange("id_cancel_reason")(e.target.value);
    setIsSaveEnabled(true);
  };

  const onSetCancelAttachment = (file) => {
    setCancelAttachment(file);
  };

  const handleClickCancel = () => {
    validationCancel.resetForm();
    setIsCancelRequestModal(false);
  };

  const handleClickCancelSubmit = () => {
    showLoading(true);
    let workflowId;
    let templateId;

    if (userGroup === "1" || userGroup === "2" || userGroup === "3") {
      workflowId = 11; //cancel request
      templateId = 10;
    } else if (userGroup === "4" || userGroup === "5") {
      workflowId = 12; //cancel request by legal
      templateId = 15;
    }

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: requestDetail.request_by,
      cc_to: "",
      comment: cancelReason,
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest, cancelAttachment);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertSubmitCancel,
          title: "In Progress",
          text: "Send a document rejection request to the user. Please wait for a response from the User to completely cancel this document.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: templateId,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: [requestDetail.request_by],
          };

          SendNotiEmail(sendEmailReq);
          history.push("/requests");
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to send document rejection request to the user.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  //#endregion Cancel

  //#region Call APIs

  const LoadRequestDetail = (requestId) => {
    let url = ConfigData.API_URL + "request/" + requestId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestDetail(respData.data[0]);
          LoadRequestWorkflowDetail(respData.data[0], 7);
          LoadRequestTemplateDetail(respData.data[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadRequestWorkflowDetail = (requestDetail, workflowStatus) => {
    let url = ConfigData.API_URL + "request-workflow/search?idRequest=" + requestDetail.id + "&idWorkflowStatus=" + workflowStatus;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          if (userGroup === "1" || userGroup === "2" || userGroup === "3") {
            setSendCommentTo(respData.data[0].send_to);
          } else if (userGroup === "4" || userGroup === "5") {
            setSendCommentTo(requestDetail.request_by);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadRequestTemplateDetail = (requestId) => {
    let url = ConfigData.API_URL + "form-csa/request/" + requestId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestTemplateDetail(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadRequestHistory = (idReq) => {
    let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestHistory(respData.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ChangeWorkflowStatus = async (dataReq, attachment) => {
    let formData = new FormData();
    formData.append("request_no", dataReq.request_no);
    formData.append("id_request", dataReq.id_request);
    formData.append("id_workflow_status", dataReq.id_workflow_status);
    formData.append("do_by", dataReq.do_by);
    formData.append("send_to", dataReq.send_to);
    formData.append("cc_to", dataReq.cc_to);
    formData.append("comment", dataReq.comment);
    formData.append("is_last", dataReq.is_last);

    if (attachment) {
      formData.append("n_document", getFilterOldFileName(attachment));
      formData = setFormDataUploadFile(formData, "f_document", attachment);
    }

    let url = ConfigData.API_URL + "request-workflow/step";

    try {
      let resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
          "User": formData.get("do_by"),
        },
        responseType: "json",
      });
      const respData = resp.data;
      return respData.is_sucess;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const SendNotiEmail = async (emailReq) => {
    const jsonReq = JSON.stringify(emailReq);
    let url = ConfigData.API_URL + "email/send";

    try {
      let resp = await axios.post(url, jsonReq, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      });
      const respData = resp.data;
      return respData.is_sucess;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const LoadUserCanDoReq = (id) => {
    let url = new URL(ConfigData.API_URL + "request/user-can-do");
    url.searchParams.set("email", userId);
    url.searchParams.set("id-request", id);
    url.searchParams.set("id-workflow", "7,8,9,12");
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess === true && respData.data.length > 0) {
          const arrUserDo = respData.data[0].user_do_request.split(",");
          if (arrUserDo.find((e) => e === userId) !== undefined) {
            LoadRequestDetail(id);
            LoadRequestHistory(id);
          } else {
            history.push("/summary-request/" + btoa(id));
          }
        } else {
          // history.push("/dashboard");
          history.push("/requests");
        }
      })
      .catch((error) => {
        // handlerRespError(error, history);
        console.log(error);
      });
  };

  //#endregion Call APIs

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12} className="mx-auto">
              <div className="body-content no-margin">
                <Label className="breadcrumb">
                  Request Detail {">"} {requestDetail.request_no}
                </Label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="mx-auto">
              {SwitchStepWorkflow()}
            </Col>
          </Row>
          {requestDetail &&
          (requestDetail.id_workflow_status === 11 ||
            requestDetail.id_workflow_status === 12) ? (
            <ApproveReject requestDetail={requestDetail} />
          ) : (
            <div></div>
          )}
          {SwitchRequestDetail()}
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              validationCancel.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col md={12}>
                <div className="work-area-container">
                  <div className="header-container header-content">
                    Work Area
                  </div>
                  <FormGroup className="mb-3">
                    <div className="body-content select-container">
                      <Label className="content-name" htmlFor="id_comment">
                        Comment<span className="msg-require">*</span>
                      </Label>
                      <Editor
                        id="comment"
                        name="id_comment"
                        value={comment}
                        setValue={setComment}
                        onChange={handleChangeComment}
                        invalid={
                          validation.touched.id_comment &&
                          validation.errors.id_comment
                            ? true
                            : undefined
                        }
                      />
                      {validation.touched.id_comment &&
                      validation.errors.id_comment ? (
                        <FormFeedback
                          type="invalid"
                          style={{ display: "block", maxWidth: "600px" }}
                        >
                          {validation.errors.id_comment}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="body-content select-container">
                      <Label className="content-name">
                        เอกสารแนบ (Attachment)
                      </Label>
                      <MultipleUploadfile
                        inputID="attachment"
                        dataFile={attachmentFile}
                        setDataFile={onSetAttachmentFile}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="body-content select-container">
                      <Label
                        className="content-name"
                        htmlFor="id_send_comment_to"
                      >
                        Send Comment To
                      </Label>
                      <Col md={4}>
                        <Input
                          type="text"
                          id="send_comment_to"
                          name="id_send_comment_to"
                          value={sendCommentTo || ""}
                          disabled
                        />
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="body-content select-container">
                      <Label className="content-name" htmlFor="ddlSendCcTo">
                        Send CC E-mail To (if any)
                      </Label>
                      <Col md={4}>
                        <div className="add-item-group">
                          <Input
                            type="email"
                            className="input-btn-inside"
                            value={sendCcToValue}
                            onChange={handleChangeSendCcTo}
                          />
                          <Button
                            type="button"
                            className="btn-add-item"
                            onClick={handleClickAddSendCcTo}
                          >
                            <i className="mdi mdi-plus" aria-hidden={true} />
                          </Button>
                        </div>
                        {sendCcToValue && !sendCcToValid ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: "block", maxWidth: "600px" }}
                          >
                            รูปแบบอีเมลไม่ถูกต้อง
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Row className="no-margin">
                        {sendCcTo.map((value, idx) => {
                          return (
                            <Label key={idx} className="selected-item">
                              {value}
                              <span
                                className="mdi mdi-close"
                                onClick={() => {
                                  handleClickRemoveSendCcTo(value);
                                }}
                              ></span>
                            </Label>
                          );
                        })}
                      </Row>
                    </div>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="work-area-container">
                  <div className="dashed-line"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="work-area-container">
                  <div className="header-container header-content">History</div>
                  <div className="history-container">
                    <RequestHistory
                      data={requestHistory}
                      column="do_date"
                      requestNo={requestDetail.request_no}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="whitespace-container"></Row>
            <Row>
              <div className="page-footer">
                <div className="layout-width-footer">
                  <div className="navbar-footer">
                    <div className="d-flex align-items-center">
                      <Button
                        type="button"
                        color="outline"
                        className="btn-base btn-back"
                        onClick={handleClickBack}
                      >
                        Back
                      </Button>
                    </div>
                    {userGroup === "1" ||
                    userGroup === "2" ||
                    userGroup === "3" ? (
                      <div className="d-flex align-items-center">
                        <div className="btn-group">
                          <Button
                            type="button"
                            className="float-end btn-base btn-reject"
                            onClick={handleClickCancelRequest}
                          >
                            Cancel Request
                          </Button>
                          <Button
                            type="button"
                            className={
                              isSaveEnabled
                                ? "float-end btn-base btn-return"
                                : "float-end btn-base btn-disable"
                            }
                            disabled={!isSaveEnabled}
                            onClick={() => {
                              validation.handleSubmit();
                              setSubmitState("return");
                            }}
                          >
                            Return
                          </Button>
                          <Button
                            type="button"
                            className={
                              isSaveEnabled
                                ? "float-end btn-base btn-submit"
                                : "float-end btn-base btn-disable"
                            }
                            disabled={!isSaveEnabled}
                            onClick={() => {
                              validation.handleSubmit();
                              setSubmitState("approve");
                            }}
                          >
                            Approve
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="btn-group">
                          <Button
                            type="button"
                            className="float-end btn-base btn-reject"
                            onClick={handleClickCancelRequest}
                          >
                            Cancel Request
                          </Button>
                          <Button
                            type="button"
                            className={
                              isSaveEnabled
                                ? "float-end btn-base btn-submit"
                                : "float-end btn-base btn-disable"
                            }
                            disabled={!isSaveEnabled}
                            onClick={() => {
                              validation.handleSubmit();
                              setSubmitState("submit");
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Row>
            <Modal
              id="cancelRequestModal"
              isOpen={isCancelRequestModal}
              toggle={() => {
                setIsCancelRequestModal(!isCancelRequestModal);
              }}
              backdrop={"static"}
              centered
            >
              <ModalHeader>
                <Row>
                  <Col md={12}>
                    <div className="modal-header-container">Cancel Request</div>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <div className="modal-body-container">
                      <Label className="modal-content-label">
                        Please input a reason for rejecting this request
                        <span className="msg-require">*</span>
                      </Label>
                      <textarea
                        id="cancelReason"
                        name="id_cancel_reason"
                        placeholder="reason"
                        className="modal-content-input"
                        value={cancelReason}
                        onChange={handleCancelReasonChange}
                        invalid={
                          validationCancel.touched.id_cancel_reason &&
                          validationCancel.errors.id_cancel_reason
                            ? true
                            : undefined
                        }
                      />
                      {validationCancel.touched.id_cancel_reason &&
                      validationCancel.errors.id_cancel_reason ? (
                        <FormFeedback
                          type="invalid"
                          className="form-feedback"
                          style={{ display: "block", minWidth: "400px" }}
                        >
                          {validationCancel.errors.id_cancel_reason}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="modal-attachment">
                      <MultipleUploadfile
                        inputID={"cancelAttachment"}
                        dataFile={cancelAttachment}
                        setDataFile={onSetCancelAttachment}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center">
                      <div className="btn-group">
                        <Button
                          type="button"
                          className="float-end btn-base btn-back"
                          onClick={handleClickCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          className={
                            isSaveEnabled
                              ? "float-end btn-base btn-submit"
                              : "float-end btn-base btn-disable"
                          }
                          disabled={!isSaveEnabled}
                          onClick={() => {
                            validationCancel.handleSubmit();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReviewComment;
