import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import TableDashboard from '../../components/common/table-dashboard';
import axios from "axios";
import ConfigData from '../../config.json';
import { groupStatusToPage } from '../../components/model/static-data';

const Dashboard = () => {
    const history = useHistory();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [dataAll, setDataAll] = useState([]);
    const [dataWaitingForApprove, setDataWaitingForApprove] = useState([]);
    const [dataInProgress, setDataInProgresss] = useState([]);
    const [dataReject, setDataReject] = useState([]);
    const [dataComplete, setDataComplete] = useState([]);

    useEffect(() => {
        changeTitlePage("Dashboard");
        LoadDataRequest();
    }, []);

    const columnAll = [
        {
            key: "request_no",
            name: <span className='font-weight-bold'>Request No.</span>,
            cell: (row, index) => row.request_no,
            width: "12%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "request_date",
            name: <span className='font-weight-bold'>Date Created</span>,
            cell: (row, index) => row.request_date,
            width: "12%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "form_temp_full_name",
            name: <span className='font-weight-bold'>Type</span>,
            cell: (row, index) => row.form_temp_full_name,
            width: "10%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "business_unit_name_en",
            name: <span className='font-weight-bold'>BU</span>,
            cell: (row, index) => row.business_unit_name_en,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "counterparty_name",
            name: <span className='font-weight-bold'>Counter party</span>,
            cell: (row, index) => row.counterparty_name,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "confidential_level_name_en",
            name: <span className='font-weight-bold'>Level</span>,
            cell: (row, index) => {
                if(row.id_confidential_level > 0){
                    return(
                        <label className={"dtc-table-level " + ((row.id_confidential_level === "2") ? "dtc-table-level-confidential" : "dtc-table-level-normal")}>{row.confidential_level_name_en}</label>
                    );
                }
            },
            width: "10%",
            classHeader: "text-center",
            classBody: "text-center",
            sortable: true
        },
        {
            key: "accept_request_by",
            name: <span className='font-weight-bold'>Legal Team</span>,
            cell: (row, index) => {
                return row.accept_request_by.split(",").join(",\r\n");
            },
            width: "16%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "id_workflow_status",
            name: <span className='font-weight-bold'>Status</span>,
            cell: (row, index) => {
                if(row.id_workflow_status === 1 || row.id_workflow_status === 2 || row.id_workflow_status === 8 
                    || row.id_workflow_status === 12 || row.id_workflow_status === 17 || row.id_workflow_status === 18 
                    || row.id_workflow_status === 20){
                    return (
                        <div className="dtc-table-status">
                            <i className="mdi mdi-clock-time-four-outline dtc-ts-wating-for-approve"></i>
                            <label>Waiting for approve</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 3 || row.id_workflow_status === 4 || row.id_workflow_status === 5
                    || row.id_workflow_status === 7 || row.id_workflow_status === 9 || row.id_workflow_status === 14
                    || row.id_workflow_status === 10 || row.id_workflow_status === 15 || row.id_workflow_status === 16
                    || row.id_workflow_status === 19 || row.id_workflow_status === 21){
                    return (
                        <div className="dtc-table-status">
                            <i className="mdi mdi-timer-sand-empty dtc-ts-in-progress"></i>
                            <label>Reviewing</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 6 || row.id_workflow_status === 11 || row.id_workflow_status === 13){
                    return (
                        <div className="dtc-table-status">
                            <i className="mdi mdi-rotate-left dtc-ts-reject"></i>
                            <label>Reject</label>
                        </div>
                    );
                }else{
                    return (
                        <div className="dtc-table-status">
                            <i className="mdi mdi-check dtdl-wfa-icon dtc-ts-complete"></i>
                            <label>Complete</label>
                        </div>
                    );
                }
            },
            width: "10%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: false
        }
    ];

    const columnForStatus = [
        {
            key: "request_no",
            name: <span className='font-weight-bold'>Request No.</span>,
            cell: (row, index) => row.request_no,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: false
        },
        {
            key: "request_date",
            name: <span className='font-weight-bold'>Date Created</span>,
            cell: (row, index) => row.request_date,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "form_temp_full_name",
            name: <span className='font-weight-bold'>Type</span>,
            cell: (row, index) => row.form_temp_full_name,
            width: "10%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "business_unit_name_en",
            name: <span className='font-weight-bold'>BU</span>,
            cell: (row, index) => row.business_unit_name_en,
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "counterparty_name",
            name: <span className='font-weight-bold'>Counter party</span>,
            cell: (row, index) => row.counterparty_name,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "confidential_level_name_en",
            name: <span className='font-weight-bold'>Level</span>,
            cell: (row, index) => {
                if(row.id_confidential_level > 0){
                    return(
                        <label className={"dtc-table-level " + ((row.id_confidential_level === "2") ? "dtc-table-level-confidential" : "dtc-table-level-normal")}>{row.confidential_level_name_en}</label>
                    );
                }
            },
            width: "10%",
            classHeader: "text-center",
            classBody: "text-center",
            sortable: true
        },
        {
            key: "accept_request_by",
            name: <span className='font-weight-bold'>Legal Team</span>,
            cell: (row, index) => {
                return row.accept_request_by.split(",").join(",\r\n");
            },
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        }
    ];

    const toggleCustomActiveTab = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const OnClickDetail = (data) => {
        let reqId = btoa(data.id);
        let urlPage = groupStatusToPage.find((e) => {
            return e.workflow_status.includes(data.id_workflow_status);
        });
        if(typeof(urlPage) !== "undefined"){
            let url = urlPage.url_page.replace(":id", reqId);
            history.push(url);
        }
    };

    const LoadDataRequest = () => {
        const email = (!localStorage.getItem("emd")) ? "--" : atob(localStorage.getItem("emd"));
        let url = ConfigData.API_URL + "request/email/" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrDataAll = respData.data;
                const arrDataWaitingForApprove = respData.data.filter((e) => e.id_workflow_status === 1 || e.id_workflow_status === 2 || e.id_workflow_status === 8 
                                                                            || e.id_workflow_status === 12 || e.id_workflow_status === 17 || e.id_workflow_status === 18 
                                                                            || e.id_workflow_status === 20);
                const arrDataInProgress = respData.data.filter((e) => e.id_workflow_status === 3 || e.id_workflow_status === 4 || e.id_workflow_status === 5
                                                                            || e.id_workflow_status === 7 || e.id_workflow_status === 9 || e.id_workflow_status === 14
                                                                            || e.id_workflow_status === 10 || e.id_workflow_status === 15 || e.id_workflow_status === 16
                                                                            || e.id_workflow_status === 19 || e.id_workflow_status === 21);
                const arrDataReject = respData.data.filter((e) => e.id_workflow_status === 6 || e.id_workflow_status === 11 || e.id_workflow_status === 13);
                const arrDataComplete = respData.data.filter((e) => e.id_workflow_status === 22);
                setDataAll(arrDataAll);
                setDataWaitingForApprove(arrDataWaitingForApprove);
                setDataInProgresss(arrDataInProgress);
                setDataReject(arrDataReject);
                setDataComplete(arrDataComplete);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="dashboard-to-do-list">
                        <Col xl={12}>
                            <p className="dtdl-title">To do list</p>
                            <Card className="dtdl-content">
                                <CardBody className="dtdl-card-body">
                                    <Row className="dtdl-row align-items-center">
                                        <Col sm={12} md={6} lg={3} className="dtdl-col">
                                            <div className="dtdl-card dtdl-div-waiting-for-approve">
                                                <div className="dtdl-card-content">
                                                    <div className="dtdl-card-icon dtdl-icon-waiting-for-approve">
                                                        <i className="mdi mdi-clock-time-four-outline dtdl-wfa-icon"></i>
                                                    </div>
                                                    <div className="dtdl-card-content-detail">
                                                        <p className="dtdl-status-title mb-0">Waiting for approve</p>
                                                        <div>
                                                            <label className="dtdl-status-amount">{dataWaitingForApprove.length}</label>
                                                            <label className="dtdl-status-msg">Requests</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={3} className="dtdl-col">
                                            <div className="dtdl-card dtdl-div-in-progress">
                                                <div className="dtdl-card-content">
                                                    <div className="dtdl-card-icon dtdl-icon-in-progress">
                                                        <i className="mdi mdi-timer-sand-empty dtdl-wfa-icon"></i>
                                                    </div>
                                                    <div className="dtdl-card-content-detail">
                                                        <p className="dtdl-status-title mb-0">In Progress</p>
                                                        <div>
                                                            <label className="dtdl-status-amount">{dataInProgress.length}</label>
                                                            <label className="dtdl-status-msg">Requests</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={3} className="dtdl-col">
                                            <div className="dtdl-card dtdl-div-reject">
                                                <div className="dtdl-card-content">
                                                    <div className="dtdl-card-icon dtdl-icon-reject">
                                                        <i className="mdi mdi-rotate-left dtdl-wfa-icon"></i>
                                                    </div>
                                                    <div className="dtdl-card-content-detail">
                                                        <p className="dtdl-status-title mb-0">Rejected</p>
                                                        <div>
                                                            <label className="dtdl-status-amount">{dataReject.length}</label>
                                                            <label className="dtdl-status-msg">Requests</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={3} className="dtdl-col">
                                            <div className="dtdl-card dtdl-div-complete">
                                                <div className="dtdl-card-content">
                                                    <div className="dtdl-card-icon dtdl-icon-complete">
                                                        <i className="mdi mdi-check dtdl-wfa-icon"></i>
                                                    </div>
                                                    <div className="dtdl-card-content-detail">
                                                        <p className="dtdl-status-title mb-0">Completed</p>
                                                        <div>
                                                            <label className="dtdl-status-amount">{dataComplete.length}</label>
                                                            <label className="dtdl-status-msg">Requests</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="dashboard-tracking-contract">
                        <Col xl={12}>
                            <p className="dtc-title">Tracking Contract</p>
                            <div className="dtc-content">
                                {/* <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"> */}
                                <Nav tabs className="nav nav-tabs nav-tabs-dashboard mb-3">
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("1");
                                            }}
                                        >
                                            All
                                            <span className="nav-noti">
                                                <label>{dataAll.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("2");
                                            }}
                                        >
                                            Waiting for approve
                                            <span className="nav-noti">
                                                <label>{dataWaitingForApprove.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "3",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("3");
                                            }}
                                        >
                                            In Progress
                                            <span className="nav-noti">
                                                <label>{dataInProgress.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "4",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("4");
                                            }}
                                        >
                                            Reject
                                            <span className="nav-noti">
                                                <label>{dataReject.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "5",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("5");
                                            }}
                                        >
                                            Complete
                                            <span className="nav-noti">
                                                <label>{dataComplete.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={customActiveTab}>
                                    <TabPane tabId="1" id="tab1">
                                        <TableDashboard data={dataAll} column={columnAll} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="2" id="tab2">
                                        <TableDashboard data={dataWaitingForApprove} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="3" id="tab3">
                                        <TableDashboard data={dataInProgress} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="4" id="tab4">
                                        <TableDashboard data={dataReject} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="5" id="tab5">
                                        <TableDashboard data={dataComplete} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;