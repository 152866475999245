import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import ConfigData from "../../config.json";
import { ConvertDataMenuForShow, setUrlMenu} from '../../components/utils/format-value';

const MenuVertical = (props) => {
    const dataMenu = (!localStorage.getItem("mnd")) ? [] : ConvertDataMenuForShow(JSON.parse(atob(localStorage.getItem("mnd"))));
    const [subMenuCurrentState, setSubMenuCurrentState] = useState("");
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSubMenuCurrentState("");
        initMenu();
    }, [props.location.pathname]);

    const OnClickSubMenu = (e, code) => {
        e.preventDefault();
        if(subMenuCurrentState !== code){
            setSubMenuCurrentState(code);
        }else{
            setSubMenuCurrentState("");
        }
    };

    const initMenu = () => {
        const pathName = props.location.pathname.replace('/', '');
        const arrPathName = pathName.split('/');
        const ul = document.getElementById("navbar-nav");
        const items = ul.getElementsByTagName("a");
        let itemsArray = [...items]; // converts NodeList to Array
        removeActivation(itemsArray);
        let matchingMenuItem = itemsArray.find((x) => {
            // let strPathURL = (pathName.replace('/', '').split('/').length > 1) ? pathName.replace('/', '').split('/')[0] : pathName.replace('/', '');
            let strPathURL = pathName;
            if(arrPathName.length > 1){
                if( arrPathName[0].toLowerCase() === "request" || arrPathName[0].toLowerCase() === "request-detail" ){
                    strPathURL = "requests";   
                } else {
                    strPathURL = arrPathName[0];
                }
            }
            let strPathMenu = x.pathname.replace('/', '');
            if(strPathMenu !== strPathURL){
                if(x.title !== ""){
                    const arrTitle = x.title.split(',');
                    strPathMenu = arrTitle.find((t) => {
                        return t === strPathURL;
                    });
                }
            }
            return strPathMenu === strPathURL;
        });

        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }

        let matchingCurrentMenu = dataMenu.find((e) => {
            // let strPathURL = (pathName.replace('/', '').split('/').length > 1) ? pathName.replace('/', '').split('/')[0] : pathName.replace('/', '');
            let strPathURL = pathName;
            if(arrPathName.length > 1){
                if( arrPathName[0].toLowerCase() === "request" || arrPathName[0].toLowerCase() === "request-detail" ){
                    strPathURL = "requests";   
                } else {
                    strPathURL = arrPathName[0];
                }
            }
            let strPathMenu = e.name.replace('/', '');
            let convertNameMenu = "";
            if(e.subItems.length > 0){
                strPathMenu = e.subItems.find((t) => {
                    if( t.name.toLowerCase() === "all request"){
                        convertNameMenu = "requests";   
                    } else if( t.name.toLowerCase() === "create request" ){
                        convertNameMenu = "create-request";
                    } else {
                        convertNameMenu = t.name.toLowerCase();
                    }
                    
                    if( strPathURL.toLowerCase() === "request" || strPathURL.toLowerCase() === "request-detail" ){
                        convertNameMenu = "requests";
                    }
    
                    return convertNameMenu === strPathURL.toLowerCase();
                });

                if(typeof(strPathMenu) !== "undefined"){
                    if( strPathMenu.name === "All Request" ){
                        strPathMenu = "requests";   
                    } else if( strPathMenu.name === "Create Request" ){
                        strPathMenu = "create-request";
                    } else {
                        strPathMenu = strPathMenu.name;
                    }
                }else{
                     strPathMenu = "";
                }
                // console.log(strPathMenu.toLowerCase() === strPathURL.toLowerCase());
            }
            return strPathMenu.toLowerCase() === strPathURL.toLowerCase();
        });

        if(matchingCurrentMenu) {
            setSubMenuCurrentState(matchingCurrentMenu.code);
        }
    };

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add("show");
            parentCollapseDiv.parentElement.children[0].classList.add("active");
            parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
            if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse")) {
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").classList.add("show");
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").previousElementSibling.classList.add("active");
                }
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items) => {
        // setSubMenuCurrentState("");
        let actiItems = items.filter((x) => x.classList.contains("active"));

        actiItems.forEach((item) => {
            if (item.classList.contains("menu-link")) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("aria-expanded", false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
            }
            if (item.classList.contains("nav-link")) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
                item.setAttribute("aria-expanded", false);
            }
            item.classList.remove("active");
        });
    };

    return (
        <React.Fragment>
            {/* menu Items */}
            {(dataMenu || []).map((item, key) => {
                return(
                    <React.Fragment key={"menu"+key}>
                        {item.subItems.length > 0 ? (
                            <li className="nav-item">
                                <Link
                                    className="nav-link menu-link"
                                    onClick={(e) => { OnClickSubMenu(e, item.code) }}
                                    title={item.page_group}
                                    to={setUrlMenu(item.page_url, item.page_param)}
                                    data-bs-toggle="collapse"
                                >
                                    <i className={item.icon_name}></i>
                                    <span data-key="t-apps">{item.name}</span>
                                </Link>
                                <Collapse id="sidebarApps"
                                    className="menu-dropdown"
                                    isOpen={(subMenuCurrentState === item.code) ? true : false}
                                >
                                    <ul className="nav nav-sm flex-column">
                                        {/* subItms  */}
                                        {(item.subItems || []).map((subItem, subKey) => (
                                            <React.Fragment key={"submenu" + key + subKey}>
                                                <li className="nav-item">
                                                    <Link
                                                        title={subItem.page_group}
                                                        to={setUrlMenu(subItem.page_url, subItem.page_param)}
                                                        className="nav-link"
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                </li>
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </Collapse>
                            </li>
                            // <div>
                            //     <p>*{item.name}</p>
                            //     {(item.subItems || []).map((subItem, subKey) => (
                            //         <React.Fragment key={"submenu" + key}>
                            //             <p>-{subItem.name} {(localPath === subItem.page_url) ? "+++++" : ""}</p>
                            //         </React.Fragment>
                            //     ))}
                            // </div>
                        ) : (
                            <li className="nav-item">
                                <Link
                                    className="nav-link menu-link"
                                    title={item.page_group}
                                    to={setUrlMenu(item.page_url, item.page_param)}
                                >
                                    <i className={item.icon_name}></i>
                                    <span>{item.name}</span>
                                </Link>
                            </li>
                            // <p>#{item.name} {(localPath === item.page_url) ? "+++++" : ""}</p>
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

MenuVertical.propTypes = {
    location: PropTypes.object,
};

export default withRouter(MenuVertical);