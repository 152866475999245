import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row, Table } from "reactstrap";

const TableModify = ({data, column, rowPerPage, onClickAdd}) =>{
    const [tableData, setTableData] = useState([]);
    const [tableSlice, setTableSlice] = useState([]);
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
    const [tableRange, setTableRange] = useState([]);
    const [inPage, setInPage] = useState(1);

    const handleSorting = (field, order) => {
        if(field){
            const sorted = [...tableData].sort((a, b) => {
                if (a[field] === null) return 1;
                if (b[field] === null) return -1;
                if (a[field] === null && b[field] === null) return 0;
                return (
                    a[field].toString().localeCompare(
                        b[field].toString(), 
                        "en", 
                        {
                            numeric: true,
                        }
                    ) * (order === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }
    };

    const handleSortingChange = (key) => {
        const sortBy = (key === sortField && sortOrder === "asc") ? "desc" : "asc";
        setSortField(key);
        setSortOrder(sortBy);
        setInPage(1);
        handleSorting(key, sortBy);
    };

    const calculateRange = (data, rowsPerPage) => {
        const range = [];
        const num = Math.ceil(data.length / rowsPerPage);
        for(let i = 1; i <= num; i++){
            range.push(i);
        }
        return range;
    };

    const handleSearchChange = (event) => {
        let arrSearch = data.filter((item) => {
            let dataMap = "";
            column.forEach(element => {
                if(element.sortable === true){
                    dataMap += " " + item[element.key];
                }
            });
            dataMap = dataMap.toLocaleLowerCase();
            return dataMap.includes(event.target.value);
        });
        setTableData(arrSearch);
        setInPage(1);
    };

    const sliceData = (data, page, rowsPerPage) => {
        return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    };
    useEffect(() => {
        setTableData(data);
    }, [data]);

    useEffect(() => {
        const range = calculateRange(tableData, rowPerPage);
        setTableRange([...range]);

        const slice = sliceData(tableData, inPage, rowPerPage);
        setTableSlice([...slice]);
    }, [tableData, setTableRange, inPage, setTableSlice, rowPerPage]);

    return(
        <React.Fragment>
            <Row className="mb-3">
                <Col md={12}>
                    <h4 className="card-title mb-0 flex-grow-1">Total {tableData.length} Unit</h4>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <div className="form-icon right">
                        <Input className="form-control" type="text" id="txtSerachTable" placeholder="Search" onChange={handleSearchChange}  />
                        <i className="ri-search-2-line"></i>
                    </div>
                </Col>
                <Col md={8}>
                    <Button className="float-end" color="success" size="md" onClick={onClickAdd}><i className="ri-add-line align-middle me-1"></i> Add</Button>
                </Col>
            </Row>
            <div className="table-responsive border-top mt-2">
                <Table className="align-middle table-hover table-modify">
                    <thead>
                        <tr>
                            {column.map((val, key) => {
                                const icon = (val.sortable === true) ? (sortField === val.key && sortOrder === "asc") ? "bx-sort-up" : (sortField === val.key && sortOrder === "desc") ? "bx-sort-down" : "bx-sort-alt-2" : "";
                                return(
                                    <th key={"th" + val.key + key} 
                                        className={"cursor-pointer " + val.classHeader} 
                                        width={val.width} 
                                        onClick={val.sortable === true ? () => handleSortingChange(val.key) : null} >
                                        {val.name}
                                        <i style={{marginLeft: 5}} className={"bx " + icon}></i>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(tableSlice.length > 0) ? tableSlice.map((valRow, keyRow) => {
                            return (
                                <tr key={"tr" + keyRow}>
                                    {column.map((valColumn, keyColumn) => {
                                        return (
                                            <td key={"td" + valColumn.key + keyColumn} 
                                                className={valColumn.classBody} 
                                                width={valColumn.width} >
                                                {valColumn.cell(valRow, keyRow)}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan={column.length}>No result found!</td></tr>}
                    </tbody>
                </Table>
                <div className="align-items-center justify-content-between d-flex">
                    <div className="flex-shrink-0">
                        <div className="text-muted">
                            Showing&nbsp;
                            <span className="fw-semibold">{tableData.length > 0 ? ((inPage - 1) * rowPerPage) + 1 : 0}</span>
                            &nbsp;to&nbsp;
                            <span className="fw-semibold">{(inPage * rowPerPage) > tableData.length ? tableData.length : inPage * rowPerPage}</span> of <span className="fw-semibold">{tableData.length}</span>
                            &nbsp;Results
                        </div>
                    </div>
                    <ul className="pagination pagination-separated pagination-sm mb-0">
                        <li className="page-item">
                            <Link to="#" className="page-link" onClick={() => { setInPage(1); }}>First</Link>
                        </li>
                        <li className="page-item">
                            <Link to="#" className="page-link" onClick={() => { setInPage((inPage - 1 <= 0) ? 1 : inPage - 1 ); }}>←</Link>
                        </li>
                        {tableRange.map((val, key) => {
                            return (
                                <li key={key} className={"page-item" + (inPage === val ? " active" : "")}>
                                    <Link to="#" className="page-link" onClick={() => { setInPage(val); }}>{val}</Link>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <Link to="#" className="page-link" onClick={() => { setInPage((inPage + 1 >= tableRange.length) ? tableRange.length : inPage + 1 ); }}>→</Link>
                        </li>
                        <li className="page-item">
                            <Link to="#" className="page-link" onClick={() => { setInPage(tableRange.length); }}>Last</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TableModify;