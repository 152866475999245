import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from "../../config.json";
import MultipleUploadfile from '../../components/common/multiple-uploadfile';
import SingleUploadfile from '../../components/common/single-uploadfile';
import { getFilterOldFileName, setFormDataUploadFile, setStringFileNameToArray } from '../../components/utils/format-value';
import MultipleDownloadfile from '../../components/common/multiple-downloadfile';
import StepWorkflow from '../../components/common/step-workflow';
import Swal from 'sweetalert2';
import { IconAlertSuccess } from '../../components/common/popup-alert';

const Memo = () => {
    const { id } = useParams();
    const history = useHistory();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [isUserFinalDoc, setIsUserFinalDoc] = useState(null);
    const [templateMemo, setTemplateMemo] = useState([]);
    const [fileUserFinalDoc, setFileUserFinalDoc] = useState([]);
    const [disableFileUserFinalDoc, setDisableFileUserFinalDoc] = useState(true);
    const [fileMemoDoc, setFileMemoDoc] = useState([]);
    const [fileAgreementDoc, setFileAgreementDoc] = useState([]);
    const [dataRequest, setDataRequest] = useState({
        "id": 0,
        "request_no": "",
        "is_user_final_doc": "",
        "final_doc": "-",
        "memo_doc": "",
        "agreement_doc": "",
        "note": "",
        "send_to": ""
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataRequest.id || 0,
            request_no: dataRequest.request_no || 0,
            is_user_final_doc: dataRequest.is_user_final_doc || "",
            final_doc: dataRequest.final_doc || "",
            memo_doc: dataRequest.memo_doc || "",
            agreement_doc: dataRequest.agreement_doc || "",
            note: dataRequest.note || "",
            send_to: dataRequest.send_to || "",
        },
        validationSchema: Yup.object({
            is_user_final_doc: Yup.string().required("กรุณาเลือกวิธีการทำเอกสารสุดท้าย/Please select document finalization option"),
            final_doc: Yup.string().required("กรุณาแนบไฟล์เอกสาร/Please attach file"),
            memo_doc: Yup.string().required("กรุณาแนบไฟล์ Memo ที่ลงนามแล้ว/Please attach the signed Memo"),
        }),
        // validate: (values) => {
        //     if(Object.keys(values).length > 0){
        //         OnFocusElement(1);
        //     }
        // },
        onSubmit: (values) => {
            SaveMemo(values);
        }
    });

    useEffect(() => {
        changeTitlePage("All Request");
        let strId = "0";
        try{
            strId = atob(id);
        }catch(error){
            strId = "0"
        }
        LoadUserCanDoReq(strId);
        LoadDataTemplateMemo();
    }, []);

    // useEffect(() => {
    //     OnFocusElement(2);
    // }, [validation.errors]);

    // const OnFocusElement = (id) => {
    //     console.log(id, validation.errors);
    //     const errors = Object.keys(validation.errors);
    //     if (errors.length > 0) {
    //         let name = errors[0];
    //         // console.log(name);
    //         // console.log(document.querySelector(`input[name='${name}']`));
    //         document.querySelector(`input[name='${name}']`).focus();
    //         // console.log(refMemoDoc.current.querySelector(`input[name='${name}']`));
    //         // document.querySelector(`input[name='${name}']`).focus();
            
            
    //     }
    // };
    
    const OnSetFileAgreementDoc = (files) => {
        setFileAgreementDoc(files);
    };

    const OnSetFileMemoDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setFileMemoDoc(file);
        validation.handleChange('memo_doc')(fileName);
    };

    const OnSetFileUserFinalDoc = (file) => {
        if(isUserFinalDoc === true){
            const fileName = (file.length > 0) ? file[0].name : "";
            setFileUserFinalDoc(file);
            validation.handleChange('final_doc')(fileName);
        }else{
            setFileUserFinalDoc([]);
            validation.handleChange('final_doc')("-");
        }
    };

    const OnClickBack = () => {
        // history.push("/dashboard");
        history.push("/requests");
    };

    const LoadDataRequest = (id) => {
        let url = ConfigData.API_URL + "request/" + id ;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const objData = {
                    "id": respData.data[0].id,
                    "request_no": respData.data[0].request_no,
                    "is_user_final_doc": respData.data[0].is_user_final_doc,
                    "final_doc": respData.data[0].final_doc,
                    "memo_doc": respData.data[0].memo_doc,
                    "agreement_doc": respData.data[0].agreement_doc,
                    "send_to": respData.data[0].accept_request_by,
                    "note": ""
                };
                LoadDataReqWorkflowStatus(respData.data[0].id, 15, objData);
                setIsUserFinalDoc((objData.is_user_final_doc === "1") ? true : (objData.is_user_final_doc === "0") ? false : null);
                setFileUserFinalDoc(setStringFileNameToArray(objData.final_doc));
                setFileMemoDoc(setStringFileNameToArray(objData.memo_doc));
                setFileAgreementDoc(setStringFileNameToArray(objData.agreement_doc));
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataReqWorkflowStatus = (idReq, idWorkflowStatus, objReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq  + "&idWorkflowStatus=" + idWorkflowStatus;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                objReq.note = respData.data[0].comment;
            }
            setDataRequest(objReq);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
            setDataRequest(objReq);
        });
    };

    const LoadDataTemplateMemo = () => {
        let url = ConfigData.API_URL + "memo/search?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setTemplateMemo(respData.data);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadUserCanDoReq = (id) => {
        let userDo = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let url = new URL(ConfigData.API_URL + "request/user-can-do");
        url.searchParams.set("email", userDo);
        url.searchParams.set("id-request", id);
        url.searchParams.set("id-workflow", "10"); 
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrUserDo = respData.data[0].user_do_request.split(",");
                if(arrUserDo.find((e) => e === userDo) !== undefined){
                    LoadDataRequest(id);
                }else{
                    history.push("/summary-request/" + btoa(id));
                }
            }else{
                // history.push("/dashboard");
                history.push("/requests");
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const SaveMemo = (values) => {
        if(values.id > 0){
            let isSaveSucess = true;
            (async () => {
                let isSaveMemo = await UpdateMemo(values);
                isSaveSucess = (isSaveMemo === true && isSaveSucess === true) ? true : false;
                let isSaveReqWorkflowStatus = await AddWorkFlowStaus(values);
                isSaveSucess = (isSaveReqWorkflowStatus === true && isSaveSucess === true) ? true : false;
                if(isSaveSucess === true){
                    SendEmail(values);
                    Swal.fire({
                        iconHtml: IconAlertSuccess,
                        title: "อยู่ระหว่างส่งข้อมูลนี้ไปยังฝ่ายกฏหมาย\nSending the information to legal team",
                        showConfirmButton: false,
                        showCloseButton: true,
                    }).then(() => {
                        // history.push("/dashboard");
                        history.push("/requests");
                    });
                }
            })();
        }
    };

    const UpdateMemo = async (data) => {
        const url = ConfigData.API_URL + "request/memo/" + data.id;
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let formData = new FormData();
        formData.append("request_no", data.request_no);
        formData.append("is_user_final_doc", data.is_user_final_doc);
        formData.append("n_final_doc", getFilterOldFileName(fileUserFinalDoc));
        formData.append("n_memo_doc", getFilterOldFileName(fileMemoDoc));
        formData.append("n_agreement_doc", getFilterOldFileName(fileAgreementDoc));
        formData = setFormDataUploadFile(formData, "f_final_doc", fileUserFinalDoc);
        formData = setFormDataUploadFile(formData, "f_memo_doc", fileMemoDoc);
        formData = setFormDataUploadFile(formData, "f_agreement_docs", fileAgreementDoc);
        try{
            const resp = await axios.put(url, formData, { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const AddWorkFlowStaus = async (dataReq) => {
        const url = ConfigData.API_URL + "request-workflow/step";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try{
            let formData = new FormData();
            formData.append("id_request", dataReq.id);
            formData.append("id_workflow_status", "15");
            formData.append("do_by", userId);
            formData.append("send_to", dataReq.send_to);
            formData.append("comment", dataReq.note);
            const resp = await axios.post(url, formData, { headers: { 
                            'Authorization' : token,
                            'User': userId,
                        }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const SendEmail = (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 18,
            "request_id": data.id,
        };
        axios.post(url, dataReq, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            // console.log(resp.data);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <p className="memo-title">Request Detail {"> " + dataRequest.request_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <StepWorkflow step={6}/>
                        </Col>
                    </Row>
                    <Row className="memo justify-content-center">
                        <Col sm={12} md={8} lg={6}>
                            <Form className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="memo-select-div">
                                    <Col lg={12} >
                                        <p className="memo-select-header">โปรดเลือก.... Select ....</p>
                                        <FormGroup>
                                            <Label className="memo-select-title">เลือกตัวเลือกสำหรับการจัดทำร่างเอกสารสุดท้าย (Select Document Finalization Option)<span className="msg-require">*</span></Label>
                                            <div className="form-check">
                                                <Input id="cbIsUserFinalDoc"
                                                    name="is_user_final_doc"
                                                    className="form-check-input memo-radio-input" 
                                                    type="radio"
                                                    checked={isUserFinalDoc === true}
                                                    onChange={() => {
                                                        setIsUserFinalDoc(true);
                                                        setDisableFileUserFinalDoc(false);
                                                        validation.handleChange('is_user_final_doc')("1");
                                                        validation.handleChange('final_doc')("");
                                                        setFileUserFinalDoc([]);
                                                    }}
                                                />
                                                <Label className="form-check-label memo-radio-label" for="cbIsUserFinalDoc">
                                                    คู่สัญญาอีกฝ่ายหนึ่งเป็นผู้จัดทำร่างเอกสารสุดท้าย (Counterparty finalize the document)
                                                    <p className="memo-radio-label-desc">หากเลือกให้คู่สัญญาอีกฝ่ายหนึ่งเป็นผู้จัดทำร่างเอกสารสุดท้าย กรุณาแนบเอกสาร (If you selected counterparty finalize the document, please attach.)</p>
                                                </Label>
                                                <SingleUploadfile inputID="userFinalDoc" folder={validation.values.request_no} dataFile={fileUserFinalDoc} setDataFile={OnSetFileUserFinalDoc} isDisable={disableFileUserFinalDoc} />
                                                { validation.touched.final_doc && validation.errors.final_doc ? 
                                                    ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.final_doc}</FormFeedback> ) : false 
                                                }
                                            </div>
                                            <div className="form-check memo-radio-space">
                                                <Input id="cbIsLegalFinalDoc"
                                                    name="is_user_final_doc"
                                                    className="form-check-input memo-radio-input" 
                                                    type="radio"
                                                    checked={isUserFinalDoc === false}
                                                    onChange={() => {
                                                        setIsUserFinalDoc(false);
                                                        setDisableFileUserFinalDoc(true);
                                                        validation.handleChange('is_user_final_doc')("0");
                                                        validation.handleChange('final_doc')("-");
                                                        setFileUserFinalDoc([]);
                                                    }}
                                                />
                                                <Label className="form-check-label memo-radio-label" for="cbIsLegalFinalDoc">
                                                    ทีมกฏหมายเป็นผู้จัดทำร่างเอกสารสุดท้าย (Legal team finalize the document)
                                                </Label>
                                            </div>
                                            { validation.touched.is_user_final_doc && validation.errors.is_user_final_doc ? 
                                                ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.is_user_final_doc}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="memo-agreement-div">
                                    <Col lg={12}>
                                        <FormGroup>
                                            <p className="memo-agreement-label">เอกสารแนบสัญญา เช่น ภาคผนวกหรือ เอกสารแนบทางเทคนิค Attachment (Ex.Annex, Schedule or technical attachment)</p>
                                            <MultipleUploadfile inputID="fileAgreementDoc" folder={validation.values.request_no} dataFile={fileAgreementDoc} setDataFile={OnSetFileAgreementDoc} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <p className="memo-file-header">ดาวน์โหลดและแนบแบบฟอร์ม Memo (Download Memo form & Attach Memo)</p>
                                        <p className="memo-file-download-label">ดาวน์โหลดแบบฟอร์ม Memo (Download Memo Form)</p>
                                        <MultipleDownloadfile folder="memo" dataFile={templateMemo} />
                                        <p className="memo-file-upload-label">แนบ Memo สำหรับการเสนอลงนาม (Attach Memo For Signing Procedure)<span className="msg-require">*</span></p>
                                        <SingleUploadfile inputID="memoDoc" folder={validation.values.request_no} dataFile={fileMemoDoc} setDataFile={OnSetFileMemoDoc} />
                                        { validation.touched.memo_doc && validation.errors.memo_doc ? 
                                            ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.memo_doc}</FormFeedback> ) : false 
                                        }
                                    </Col>
                                </Row>
                                <Row className="memo-note-div">
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="memo-note-label" htmlFor="txtNote">Note</Label>
                                            <Input id="txtNote"
                                                name="note"
                                                placeholder="Note"
                                                type="textarea"
                                                className="from-control memo-note-input"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.note || ""}
                                                invalid={ validation.touched.note && validation.errors.note ? true : false }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="page-footer">
                                    <div className="layout-width-footer">
                                        <div className="navbar-footer2">
                                            <Button type="button" className="memo-btn-back" onClick={(e) => { OnClickBack(); }}>
                                                Back
                                            </Button>
                                            <Button type="submit" color='primary' className="memo-btn-submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Memo;