import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import ReactQuill, { Quill } from "react-quill";
import "../../assets/scss/plugins/_quill-editor.scss";
import "react-quill/dist/quill.snow.css";

const Editor = (props) => {
  useEffect(() => {}, [props]);

  const fontSizeArray = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "36px",
    "48px",
  ];

  const fontSizeStyle = Quill.import("attributors/style/size");
  fontSizeStyle.whitelist = fontSizeArray;
  Quill.register(fontSizeStyle, true);

  const handleChangeContent = (event) => {
    if (event === "<p><br></p>") {
      event = "";
    }

    props.setValue(event);
    props.onChange(event);
    // console.log("handleChangeContent >> ", event);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <div className="wrapper">
            <ReactQuill
              className="editor"
              placeholder="Edit your content here!"
              theme="snow"
              value={props.value}
              setValue={props.setValue}
              onChange={handleChangeContent}
              modules={{
                toolbar: {
                  container: [
                    [
                      { size: fontSizeStyle.whitelist },
                      { color: [] },
                      { background: [] },
                    ],
                    [
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      { script: "sub" },
                      { script: "super" },
                    ],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["clean"],
                  ],
                },
              }}
              formats={{
                formats: [
                  "size",
                  "color",
                  "background",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "script",
                  "list",
                  "bullet",
                  "indent",
                ],
              }}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Editor;
