import React, { useContext, useEffect, useState } from 'react';
import ProfileDropdown from '../components/common/profile-dropdown';
import { GolbalDataContext } from '../components/store/global-data-provider'

const Header = () => {
    const fullName = (!localStorage.getItem("nmd")) ? "" : atob(localStorage.getItem("nmd"));
    const { titlePage } = useContext(GolbalDataContext);

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize <= 767) {
            document.body.classList.add('vertical-sidebar-enable');
        }
    };

    return (
        <React.Fragment>
            <header className="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex align-items-center">
                            <button onClick={toogleMenuBtn} type="button" id="topnav-hamburger-icon" className="btn btn-sm fs-16 hamburger-cursor header-item">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                            <span className="topbar-span-title">
                                <label className="topbar-title">{titlePage}</label>
                                <br className="d-none" />
                                <label className="d-none"></label>
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;