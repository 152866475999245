import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from 'sweetalert2';
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from "../../config.json";
import StepWorkflow from '../../components/common/step-workflow';
import MultipleDownloadfile from '../../components/common/multiple-downloadfile';
import RequestHistory from '../../components/request/request-history';
import SingleUploadfile from '../../components/common/single-uploadfile';
import { getFilterOldFileName, setFormDataUploadFile } from '../../components/utils/format-value';
import { IconAlertReject, IconAlertSuccess } from '../../components/common/popup-alert';
import RequestFinalApprover from '../../components/request/request-final-approver';

const FinalContract = () => {
    const history = useHistory();
    const { id } = useParams();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [fileMemo, setFileMemo] = useState([]);
    const [fileFinalContract, setFileFinalContract] = useState([]);
    const [fileRejectFinalContractDoc, setFileRejectFinalContractDoc] = useState([]);
    const [lastFinalApprover, setLastFinalApprover] = useState("");
    const [isShowModal, setIsShowModal] = useState();
    const [dataHistory, setDataHistory] = useState([]);
    const [dataRequest, setDataRequest] = useState({
        "id": 0,
        "id_workflow_status": 0,
        "request_no": "",
        "request_by": "",
        "approve_request_by": "",
        "accept_request_by": "",
        "final_doc": "",
        "memo_doc": "",
        "agreement_doc": "",
        "select_user_approve_by": "",
        "user1_approve_by": "",
        "user2_approve_by": "",
        "user3_approve_by": "",
        "select_legal_approve_by": "",
        "legal1_approve_by": "",
        "legal2_approve_by": "",
        "legal3_approve_by": "",
        "note": "",
        "consent": false,
        "send_to": "",
        "final": false
    });
    const [dataFinalApprover, setDataFinalApprover] = useState({
        "user1_approve_by": "",
        "user2_approve_by": "",
        "user3_approve_by": "",
        "legal1_approve_by": "",
        "legal2_approve_by": "",
        "legal3_approve_by": ""
    });

    const [dataIsCan, setDataIsCan] = useState({
        "can_view": false,
        "can_approve": false,
        "can_reject": false,
    });

    useEffect(() => {
        changeTitlePage("All Request");
        let strId = "0";
        try{
            strId = atob(id);
        }catch(error){
            strId = "0";
        }
        LoadDataUserApproveFinal(strId);
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id_request: dataRequest.id || 0,
            request_no: dataRequest.request_no || "",
            select_legal_approve_by: dataRequest.select_legal_approve_by || "",
            legal1_approve_by: dataRequest.legal1_approve_by || "",
            legal2_approve_by: dataRequest.legal2_approve_by || "",
            legal3_approve_by: dataRequest.legal3_approve_by || "",
            select_user_approve_by: dataRequest.select_user_approve_by || "",
            user1_approve_by: dataRequest.user1_approve_by || "",
            user2_approve_by: dataRequest.user2_approve_by || "",
            user3_approve_by: dataRequest.user3_approve_by || "",
            note: dataRequest.note || "",
            consent: dataRequest.consent || false,
            send_to: dataRequest.send_to || "",
            final: dataRequest.final || false,
        },
        validationSchema: Yup.object({
            // note: Yup.string().required("Please enter a note"),
            consent: Yup.boolean().oneOf([true], "กรุณากดยอมรับเพื่อให้การอนุมัติเอกสารเสร็จสมบูรณ์/Please tick this box to complete the approval."),
        }),
        onSubmit: (values) => {
            ApproveFinalContract(values);
        }
    });

    const validationReject = useFormik({
        enableReinitialize: true,
        initialValues: {
            id_request: dataRequest.id || 0,
            request_no: dataRequest.request_no || "",
            note: dataRequest.note || "",
            document: "",
            send_to: dataRequest.accept_request_by || "",
        },
        validationSchema: Yup.object({
            note: Yup.string().required("Please enter a note"),
        }),
        onSubmit: (values) => {
            // console.log(values);
            RejectFinalContract(values);
        }
    });

    const OnClickReject = () => {
        validationReject.resetForm();
        setFileRejectFinalContractDoc([]);
        setIsShowModal(!isShowModal);
    };

    const OnClickBack = () => {
        // history.push("/dashboard");
        history.push("/requests");
    };

    const OnSetFileRejectFinalContract = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setFileRejectFinalContractDoc(file);
        validationReject.handleChange('document')(fileName);
    };

    const LoadDataUserApproveFinal = (id) => {
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let url = new URL(ConfigData.API_URL + "request-workflow/user-approve-final");
        url.searchParams.set("email", userId);
        url.searchParams.set("id-req", id);
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrUserDo = respData.data[0].do_by.split(",");
                if(arrUserDo.find((e) => e === userId) !== undefined){
                    LoadDataRequest(id);
                    LoadDataHistory(id);
                    LoadDataLastFinalApprover(id);
                    dataRequest.send_to = (respData.data[0].send_to === "FINAL") ? respData.data[0].accept_request_by : respData.data[0].send_to;
                    dataRequest.final = (respData.data[0].send_to === "FINAL") ? true : false;    
                }else{
                    history.push("/summary-request/" + btoa(id));
                }
            }else{
                // history.push("/dashboard");
                history.push("/requests");
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };
 
    const LoadDataRequest = (id) => {
        let url = ConfigData.API_URL + "request/" + id ;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                dataRequest.id = respData.data[0].id;
                dataRequest.id_workflow_status = respData.data[0].id_workflow_status;
                dataRequest.request_no = respData.data[0].request_no;
                dataRequest.request_by = respData.data[0].request_by;
                dataRequest.accept_request_by = respData.data[0].accept_request_by;
                dataRequest.approve_request_by = respData.data[0].approve_request_by;
                dataRequest.final_doc = respData.data[0].final_doc;
                dataRequest.memo_doc = respData.data[0].memo_doc;
                dataRequest.agreement_doc = respData.data[0].agreement_doc;
                dataRequest.select_user_approve_by = respData.data[0].select_user_approve_by;
                dataRequest.user1_approve_by = respData.data[0].user1_approve_by;
                dataRequest.user2_approve_by = respData.data[0].user2_approve_by;
                dataRequest.user3_approve_by = respData.data[0].user3_approve_by;
                dataRequest.select_legal_approve_by = respData.data[0].select_legal_approve_by;
                dataRequest.legal1_approve_by = respData.data[0].legal1_approve_by;
                dataRequest.legal2_approve_by = respData.data[0].legal2_approve_by;
                dataRequest.legal3_approve_by = respData.data[0].legal3_approve_by;
                setFileFinalContract([{"name": respData.data[0].final_doc}]);
                setFileMemo([{"name": respData.data[0].memo_doc}]);
                const objDataApprover = {
                    "user1_approve_by": respData.data[0].user1_approve_by,
                    "user2_approve_by": respData.data[0].user2_approve_by,
                    "user3_approve_by": respData.data[0].user3_approve_by,
                    "legal1_approve_by": respData.data[0].legal1_approve_by,
                    "legal2_approve_by": respData.data[0].legal2_approve_by,
                    "legal3_approve_by": respData.data[0].legal3_approve_by,
                };
                setDataFinalApprover(objDataApprover);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataLastFinalApprover = (idReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq  + "&idWorkflowStatus=18";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: {
            'Authorization' : token,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setLastFinalApprover(respData.data[0].do_by);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataHistory = (idReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" }) 
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataHistory(respData.data);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const ApproveFinalContract = (dataReq) => {
        let isLast = (dataReq.final === true) ? "1" : "0";
        let url = ConfigData.API_URL + "request-workflow/step";
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let formData = new FormData();
        formData.append("id_request", dataReq.id_request);
        formData.append("request_no", dataReq.request_no);
        formData.append("id_workflow_status", "18");
        formData.append("do_by", userId);
        formData.append("send_to", dataReq.send_to);
        // formData.append("cc_to", dataReq.cc_to);
        formData.append("comment", dataReq.note);
        formData.append("consent", (dataReq.consent === true) ? "1" : "0");
        formData.append("is_last", isLast);
        // formData.append("n_document", getFilterOldFileName(fileRejectFinalContractDoc));
        // formData.append("f_document", fileRejectFinalContractDoc);
        axios.post(url, formData, { headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                SendEmail(dataReq, true, isLast);
                Swal.fire({
                    iconHtml: IconAlertSuccess,
                    title: "ร่างเอกสารฉบับสุดท้าย\nได้รับการอนุมัติแล้ว\nApproved Final Document",
                    showConfirmButton: false,
                    showCloseButton: true,
                }).then(() => {
                    // history.push("/dashboard");
                    history.push("/requests");
                });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const RejectFinalContract = (dataReq) => {
        let url = ConfigData.API_URL + "request-workflow/step";
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let formData = new FormData();
        formData.append("id_request", dataReq.id_request);
        formData.append("request_no", dataReq.request_no);
        formData.append("id_workflow_status", "19");
        formData.append("do_by", userId);
        formData.append("send_to", dataReq.send_to);
        formData.append("comment", dataReq.note);
        formData.append("n_document", getFilterOldFileName(fileRejectFinalContractDoc));
        formData = setFormDataUploadFile(formData, "f_document", fileRejectFinalContractDoc);
        axios.post(url, formData, { headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                SendEmail(dataReq, false, "0");
                Swal.fire({
                    iconHtml: IconAlertReject,
                    title: "ส่งกลับไปยังฝ่ายกฏหมาย\nSending back to the Legal Team",
                    showConfirmButton: false,
                    showCloseButton: true,
                }).then(() => {
                    // history.push("/dashboard");
                    history.push("/requests");
                });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const SendEmail = (data, isApprove, isLast) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let idTemplate = (isApprove === true) ? ((isLast === "1") ? 14 : 12) : 13;
        let dataReq = {
            "to": arrTo,
            "template_id": idTemplate,
            "request_id": data.id_request,
        };
        axios.post(url, dataReq, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            // console.log(resp.data);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="final-contract">
                    <Row>
                        <Col lg={12}>
                            <p className="fc-title">Request Detail {"> " + dataRequest.request_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <StepWorkflow step={8} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <Form className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="fc-detail">
                                    <Col lg={12}>
                                        <p className="fc-detail-title">จัดทำร่างเอกสารสุดท้าย (Finalize Document)</p>
                                        <p className="fc-detail-contractno-label">เลขที่คำขอ (Request No.)</p>
                                        <p className="fc-detail-contractno-value">{dataRequest.request_no}</p>
                                        <p className="fc-detail-file-label">ร่างเอกสารสุดท้ายและเอกสารแนบ (Finalized Document and Attachment)</p>
                                        <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileFinalContract} />
                                        <p className="fc-detail-memo-label">Memo สำหรับเสนอลงนาม (Memo for Signing Procedure)</p>
                                        <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileMemo} />
                                        <p className="fc-detail-title mt-4">ผู้อนุมัติขั้นตอนสุดท้าย (Final approver(s))</p>
                                        <p className="fc-detail-contractno-label">อนุมัติตามลำดับ ดังนี้ (following approval sequence.)</p>
                                        <RequestFinalApprover dataReq={dataFinalApprover} lastApprover={lastFinalApprover} />
                                    </Col>
                                </Row>
                                <Row className="fc-workarea">
                                    <Col lg={12}>
                                        <p className="fc-workarea-title">Work Area</p>
                                        <Row className="fc-workarea-note-div">
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label className="fc-workarea-note-label" htmlFor="txtNote">Note</Label>
                                                    <Input id="txtNote"
                                                        name="note"
                                                        placeholder="Note"
                                                        type="textarea"
                                                        className="from-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.note || ""}
                                                        invalid={ validation.touched.note && validation.errors.note ? true : false } 
                                                    />
                                                    { validation.touched.note && validation.errors.note ? 
                                                        ( <FormFeedback type="invalid">{validation.errors.note}</FormFeedback> ) : false 
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <FormGroup>
                                                    <Input id="cbConsent"
                                                        name="consent"
                                                        type="checkbox"  
                                                        className="form-check-input" 
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.consent || ""}
                                                        invalid={ validation.touched.consent && validation.errors.consent ? true : false } 
                                                    />
                                                    <Label className="form-check-label fc-workarea-consent-label" for="cbConsent">
                                                        ฉันได้ตรวจสอบและอนุมัติร่างเอกสารและ Memo ฉบับสุดท้ายนี้แล้ว<br/>
                                                        <span className="fc-workarea-consent-label-desc">I have reviewed and approved the final version of contract amd memo.</span>
                                                    </Label>
                                                    { validation.touched.consent && validation.errors.consent ? 
                                                        ( <FormFeedback type="invalid">{validation.errors.consent}</FormFeedback> ) : false 
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="page-footer">
                                    <div className="layout-width-footer">
                                        <div className="navbar-footer2">
                                            <Button type="button" 
                                                className="fc-btn-back"
                                                onClick={(e) => { OnClickBack(); }}
                                            >
                                                Back
                                            </Button>
                                            <span>
                                                <Button type="button" color="warning"
                                                    className="fc-btn-reject"
                                                    onClick={OnClickReject}
                                                >
                                                    Reject
                                                </Button>
                                                <Button type="submit" color='primary' 
                                                    className="fc-btn-approve"
                                                    // className={"fc-btn-approve " + ((!dataIsCan.can_approve) ? "d-none" : "")}
                                                >
                                                    Approve
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="justify-content-center fc-history-div">
                        <Col sm={12} md={11} lg={11}>
                            <p className="fc-history-title">History</p>
                            <RequestHistory data={dataHistory} column="do_date" requestNo={dataRequest.request_no} />
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Modal Reject */}
            <Modal id="modalRejectFinalRequest"
                isOpen={isShowModal}
                className="final-contract-reject-modal"
                toggle={() => { setIsShowModal(!isShowModal); }}
                backdrop={'static'}
                centered
            >
                <ModalHeader id="modalRejectFinalRequest"
                    className="modal-title"
                    toggle={() => { setIsShowModal(!isShowModal); }}
                >
                    <label className="fc-reject-header">ปฏิเสธ (Reject)</label>
                </ModalHeader>
                <ModalBody>
                    <Form className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validationReject.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={12} className="text-start">
                                <FormGroup>
                                    <Label className="fc-reject-note-label" htmlFor="txtNote">
                                        โปรดระบุเหตุผลในการปฏิเสธคำขอนี้<br/>
                                        Please input a reason for rejecting this request
                                        <span className="msg-require">*</span>
                                    </Label>
                                    <Input id="txtNote"
                                        name="note"
                                        placeholder="เหตุผล (Reason)"
                                        type="textarea"
                                        className="from-control"
                                        onChange={validationReject.handleChange}
                                        onBlur={validationReject.handleBlur}
                                        value={validationReject.values.note || ""}
                                        invalid={ validationReject.touched.note && validationReject.errors.note ? true : false } 
                                    />
                                    { validationReject.touched.note && validationReject.errors.note ? 
                                        ( <FormFeedback type="invalid">{validationReject.errors.note}</FormFeedback> ) : false 
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="text-start">
                                <SingleUploadfile inputID="rejectFinalContractDoc" folder={validationReject.values.request_no} dataFile={fileRejectFinalContractDoc} setDataFile={OnSetFileRejectFinalContract} />
                            </Col>
                        </Row>
                        <Row className="fc-reject-btn-div">
                            <Col lg={12}>
                                <Button className="fc-reject-submit float-end" color="primary" type="submit">
                                    Submit
                                </Button>
                                <Button className="fc-reject-cancel float-end" type="button" onClick={() => setIsShowModal(false) } >
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>

            </Modal>
        </React.Fragment>
    );
};

export default FinalContract;