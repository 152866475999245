import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, FormFeedback, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import "../../assets/scss/pages/_assign-work.scss";
import MultipleUploadfile from "../../components/common/multiple-uploadfile";
import ConfigData from "../../config.json";
import axios from "axios";
import StepWorkflow from "../../components/common/step-workflow";
import { getFilterOldFileName, setFormDataUploadFile } from "../../components/utils/format-value";
import { GolbalDataContext } from "../../components/store/global-data-provider";
import AssignDetailCsa from "../../components/assign/assign-detail-csa";
import Swal from "sweetalert2";
import { IconAlertCancel, IconAlertSuccess } from "../../components/common/popup-alert";
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const AcceptAssign = () => {
  const accessToken = localStorage.getItem("tkd");
  const userId = !localStorage.getItem("emd") ? "" : atob(localStorage.getItem("emd"));
  const { id } = useParams();
  const history = useHistory();
  const { changeTitlePage } = useContext(GolbalDataContext);
  const [requestDetail, setRequestDetail] = useState([]);
  const [requestWorkflowDetail, setRequestWorkflowDetail] = useState([]);
  const [requestTemplateDetail, setRequestTemplateDetail] = useState([]);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [rejectAttachment, setRejectAttachment] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    let strId = "0";
    try {
      strId = atob(id);
    } catch (error) {
      strId = "0";
    }
    showLoading(true);
    changeTitlePage("All Request");
    LoadUserCanDoReq(strId);
    showLoading(false);
  }, [id]);

  const validationReject = useFormik({
    enableReinitialize: true,
    initialValues: {
      id_reject_reason: "",
    },
    validationSchema: Yup.object({
      id_reject_reason: Yup.string().required("กรุณาระบุความคิดเห็น"),
    }),
    onSubmit: () => {
      handleClickRejectSubmit();
    },
  });

  const showLoading = (isShow) => {
    if (isShow) {
      Swal.fire({
        icon: "info",
        title: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  };

  const SwitchRequestDetail = () => {
    switch (requestDetail.table_name?.toLowerCase()) {
      case "csa":
        return (
          <AssignDetailCsa
            requestDetail={requestDetail}
            requestTemplateDetail={requestTemplateDetail}
          />
        );
      default:
        return <div></div>;
    }
  };

  const handleClickBack = () => {
    history.push("/requests");
  };

  const handleClickReject = () => {
    setIsRejectModal(true);
  };

  const handleClickAccept = () => {
    showLoading(true);
    let workflowId = 7; //accept request

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: requestWorkflowDetail.send_to,
      comment: "",
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest, null);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertSuccess,
          title: "The request has been accepted.",
          text: "You have accepted this request. Press OK to continue with the document.",
          showConfirmButton: true,
          confirmButtonText: "OK",
          confirmButtonColor: "#0778FF",
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 6,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: [requestDetail.request_by],
            cc: [],
          };

          SendNotiEmail(sendEmailReq);
          history.push("/review-comment/" + btoa(requestDetail.id));
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to accepted this request.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  //#region Reject

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
    validationReject.handleChange("id_reject_reason")(e.target.value);
    setIsSaveEnabled(true);
  };

  const onSetRejectAttachment = (file) => {
    setRejectAttachment(file);
  };

  const handleClickRejectCancel = () => {
    validationReject.resetForm();
    setIsRejectModal(false);
  };

  const handleClickRejectSubmit = () => {
    showLoading(true);
    let workflowId = 6; //reject request

    const changeStatusRequest = {
      request_no: requestDetail.request_no,
      id_request: requestDetail.id,
      id_workflow_status: workflowId,
      do_by: userId,
      send_to: requestDetail.request_by,
      comment: rejectReason,
      document: rejectAttachment.map((obj) => obj.name).join(","),
      is_last: 0,
    };

    (async () => {
      let result = await ChangeWorkflowStatus(changeStatusRequest, rejectAttachment);

      if (result) {
        showLoading(false);
        Swal.fire({
          iconHtml: IconAlertCancel,
          title: "The request has been canceled.",
          showConfirmButton: false,
          showCloseButton: true,
        }).then(() => {
          const sendEmailReq = {
            template_id: 4,
            request_id: requestDetail.id,
            workflow_id: workflowId,
            to: [requestDetail.request_by],
            cc: [],
          };

          SendNotiEmail(sendEmailReq);
          history.push("/requests");
        });
      } else {
        showLoading(false);
        Swal.fire({
          icon: "error",
          title: "Failed to cancel request.",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    })();
  };

  //#endregion Reject

  //#region Call APIs

  const LoadRequestDetail = (requestId) => {
    let url = ConfigData.API_URL + "request/" + requestId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestDetail(respData.data[0]);
          LoadRequestWorkflowDetail(respData.data[0].id, 5);
          LoadRequestTemplateDetail(respData.data[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadRequestWorkflowDetail = (requestId, workflowStatus) => {
    let url = ConfigData.API_URL + "request-workflow/search?idRequest=" + requestId + "&idWorkflowStatus=" + workflowStatus;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestWorkflowDetail(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoadRequestTemplateDetail = (requestId) => {
    let url = ConfigData.API_URL + "form-csa/request/" + requestId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess && respData.data.length > 0) {
          setRequestTemplateDetail(respData.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ChangeWorkflowStatus = async (dataReq, attachment) => {
    let formData = new FormData();
    formData.append("request_no", dataReq.request_no);
    formData.append("id_request", dataReq.id_request);
    formData.append("id_workflow_status", dataReq.id_workflow_status);
    formData.append("do_by", dataReq.do_by);
    formData.append("send_to", dataReq.send_to);
    formData.append("comment", dataReq.comment);
    formData.append("is_last", dataReq.is_last);

    if (attachment) {
      formData.append("n_document", getFilterOldFileName(attachment));
      formData = setFormDataUploadFile(formData, "f_document", attachment);
    }

    let url = ConfigData.API_URL + "request-workflow/step";

    try {
      let resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
          "User": formData.get("do_by"),
        },
        responseType: "json",
      });
      const respData = resp.data;
      return respData.is_sucess;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const SendNotiEmail = async (emailReq) => {
    const jsonReq = JSON.stringify(emailReq);
    let url = ConfigData.API_URL + "email/send";

    try {
      let resp = await axios.post(url, jsonReq, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      });
      const respData = resp.data;
      return respData.is_sucess;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const LoadUserCanDoReq = (id) => {
    let url = new URL(ConfigData.API_URL + "request/user-can-do");
    url.searchParams.set("email", userId);
    url.searchParams.set("id-request", id);
    url.searchParams.set("id-workflow", "5");
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        responseType: "json",
      })
      .then((resp) => {
        const respData = resp.data;
        if (respData.is_sucess === true && respData.data.length > 0) {
          const arrUserDo = respData.data[0].user_do_request.split(",");
          if (arrUserDo.find((e) => e === userId) !== undefined) {
            LoadRequestDetail(id);
          } else {
            history.push("/summary-request/" + btoa(id));
          }
        } else {
          // history.push("/dashboard");
          history.push("/requests");
        }
      })
      .catch((error) => {
        // handlerRespError(error, history);
        console.log(error);
      });
  };

  //#endregion Call APIs

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12} className="mx-auto">
              <div className="body-content no-margin">
                <Label className="breadcrumb">
                  Request Detail {">"} {requestDetail.request_no}
                </Label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="mx-auto">
              <StepWorkflow step={3} />
            </Col>
          </Row>
          {SwitchRequestDetail()}
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validationReject.handleSubmit();
              return false;
            }}
          >
            <Row className="whitespace-container"></Row>
            <Row>
              <div className="page-footer">
                <div className="layout-width-footer">
                  <div className="navbar-footer">
                    <div className="d-flex align-items-center">
                      <Button
                        type="button"
                        color="outline"
                        className="btn-base btn-back"
                        onClick={handleClickBack}
                      >
                        Back
                      </Button>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="btn-group">
                        <Button
                          type="button"
                          className="float-end btn-base btn-reject"
                          onClick={handleClickReject}
                        >
                          Reject
                        </Button>
                        <Button
                          type="button"
                          className="float-end btn-base btn-submit"
                          onClick={handleClickAccept}
                        >
                          Accept Request
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Modal
              id="rejectModal"
              isOpen={isRejectModal}
              toggle={() => {
                setIsRejectModal(!isRejectModal);
              }}
              backdrop={"static"}
              centered
            >
              <ModalHeader>
                <Row>
                  <Col md={12}>
                    <div className="modal-header-container">Reject</div>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <div className="modal-body-container">
                      <Label className="modal-content-label">
                        Please input a reason for rejecting this request
                        <span className="msg-require">*</span>
                      </Label>
                      <textarea
                        id="rejectReason"
                        name="id_reject_reason"
                        placeholder="reason"
                        className="modal-content-input"
                        value={rejectReason}
                        onChange={handleChangeRejectReason}
                        invalid={
                          validationReject.touched.id_reject_reason &&
                          validationReject.errors.id_reject_reason
                            ? true
                            : undefined
                        }
                      />
                      {validationReject.touched.id_reject_reason &&
                      validationReject.errors.id_reject_reason ? (
                        <FormFeedback
                          type="invalid"
                          className="form-feedback"
                          style={{ display: "block", minWidth: "400px" }}
                        >
                          {validationReject.errors.id_reject_reason}
                        </FormFeedback>
                      ) : null}
                      <MultipleUploadfile
                        inputID={"rejectAttachment"}
                        dataFile={rejectAttachment}
                        setDataFile={onSetRejectAttachment}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center">
                      <div className="btn-group">
                        <Button
                          type="button"
                          className="float-end btn-base btn-back"
                          onClick={handleClickRejectCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          className={isSaveEnabled ? "float-end btn-base btn-submit" : "float-end btn-base btn-disable"}
                          disabled={!isSaveEnabled}
                          onClick={() => {
                            validationReject.handleSubmit();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcceptAssign;
