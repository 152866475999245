import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import avatar1 from "../../assets/images/user-dummy-img.jpg";

const ProfileDropdown = () => {
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const userName = (!localStorage.getItem("nmd")) ? "" : atob(localStorage.getItem("nmd"));
    // const position = "Position";
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                 <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <span className="text-start ms-xl-2 user-name-text">
                            {userName}
                        </span>
                    </span>
                 </DropdownToggle>
                 <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="/login">
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle" data-key="t-logout">Logout</span>
                    </DropdownItem>
                 </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;