import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, FormGroup, Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from '../../config.json';
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const ChooseRequestForm = ({ typeCallback, nextStep }) => {
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [dataSubRequest, setDataSubRequest] = useState([]);
    const [dataSubDivisionRequest, setDataSubDivisionRequest] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState("");
    const [ddlRequest, setDdlRequest] = useState([]);
    const [selectedSubRequest, setSelectedSubRequest] = useState("");
    const [ddlSubRequest, setDdlSubRequest] = useState([]);
    const [selectedSubDivisionRequest, setSelectedSubDivisionRequest] = useState("");
    const [ddlSubDivisionRequest, setDdlSubDivisionRequest] = useState([]);
    const [dataDetail, setDataDetail] = useState({
      id: 0,
      id_request_type: "",
      id_sub_request_type:"",
      id_sub_division_request_type:"",
      name_th: "",
      name_en: ""
    });
    const history = useHistory();

    useEffect(() => {
        changeTitlePage("Create Request");
        LoaddataRequestType();
        LoadDataSubRequestType();
        LoadDataSubDivisionRequestType();
    } , []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataDetail.id || 0,
            id_request_type: dataDetail.id_request_type || "",
            id_sub_request_type: dataDetail.id_sub_request_type || "",
            id_sub_division_request_type: dataDetail.id_sub_division_request_type || "",
        },
        validationSchema: Yup.object({
            id_request_type: Yup.string().required("กรุณาเลือก/Please select"),
            id_sub_request_type: Yup.string().required("กรุณาเลือก/Please select"),
        //   id_sub_division_request_type: Yup.string().required("Please select a Type of Contract"),
        }),
        onSubmit: (values) => {
            values.id_request_type = (values.id_request_type !== "") ? values.id_request_type : "";
            values.id_sub_request_type = (values.id_sub_request_type !== "") ? values.id_sub_request_type : "";
            values.id_sub_division_request_type = (values.id_sub_division_request_type !== "") ? values.id_sub_division_request_type : "";
        //   console.log(values);
            return values;
        }
    });

    const handleBack = () => {
      history.push("/requests");
    };

    const LoaddataRequestType = () => {
        let url = ConfigData.API_URL + "request-type/ddl?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                let arrData = respData.data.map((e) => {
                    return {
                        label: e.name_en,
                        value: e.id.toString(),
                        id: e.id.toString(),
                    };
                });
                setDdlRequest(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataSubRequestType = () => {
        let url = ConfigData.API_URL + "sub-request-type/ddl?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            // console.log(respData);
            if(respData.is_sucess === true){
                let arrData = respData.data.map((e) => {
                    return {
                        label: e.name_en,
                        value: e.id.toString(),
                        id: e.id.toString(),
                        id_request_type: e.id_request_type.toString(),
                    };
                });
                setDataSubRequest(arrData);
                // setDdlSubRequest(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataSubDivisionRequestType = () => {
        let url = ConfigData.API_URL + "division-sub-request-type/ddl?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            // console.log(respData);
            if(respData.is_sucess === true){
                let arrData = respData.data.map((e) => {
                    return {
                        label: e.name_en,
                        value: e.id.toString(),
                        id: e.id.toString(),
                        id_request_type: e.id_request_type.toString(),
                        id_sub_request_type: e.id_sub_request_type.toString(),
                    };
                });
                setDataSubDivisionRequest(arrData);
                // setDdlSubRequest(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };
    return (
        <React.Fragment>
          <Row>
              <Col xl={6} className="mx-auto">
              <div>
                  <span className="span-type-request">เลือกประเภทของคำขอ (Select Type of Request)</span>
              </div>
              </Col>
          </Row>
          <br/>
          <Row>
              <Col xl={6} className="mx-auto">
                  <FormGroup className="mb-3">
                      <Label htmlFor="ddlRequest" className="label-ddl">ประเภทคำขอ (Type of Request)<span className="msg-require">*</span></Label>
                      <Select id="ddlRequest"
                          name="id_request_type"
                          value={selectedRequest}
                          className="txt-placeholder"
                          placeholder={"โปรดเลือก..."}
                          onChange={(selected) => {
                              let id = (selected !== null) ? selected.value : "";
                              setDdlSubRequest(dataSubRequest.filter((e) => e.id_request_type === id));
                              setSelectedRequest(selected);
                              setSelectedSubRequest(null);
                              validation.handleChange('id_request_type')((selected !== null) ? selected.value : "");
                              // validation.handleChange('id_request_type')("");
                          }}
                          options={ddlRequest}
                          isClearable={true}
                          isSearchable={true}
                          invalid={ validation.touched.id_request_type && validation.errors.id_request_type ? true : false }
                      />
                      { validation.touched.id_request_type && validation.errors.id_request_type ? 
                          ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.id_request_type}</FormFeedback> ) : false 
                      }
                  </FormGroup>
              </Col>
          </Row>
          <Row>
              <Col xl={6} className="mx-auto">
                  <FormGroup className="mb-3">
                      <Label htmlFor="ddlSubRequest" className="label-ddl">ประเภทสัญญา (Type of Contract)<span className="msg-require">*</span></Label>
                      <Select id="ddlSubRequest"
                          name="id_sub_request_type"
                          value={selectedSubRequest}
                          className="txt-placeholder"
                          placeholder={"โปรดเลือก..."}
                          onChange={(selected) => {
                              setSelectedSubRequest(selected);
                              validation.handleChange('id_sub_request_type')((selected !== null) ? selected.value : "");
                          }}
                          options={ddlSubRequest}
                          isClearable={true}
                          isSearchable={true}
                          invalid={ validation.touched.id_sub_request_type && validation.errors.id_sub_request_type ? true : false }
                      />
                      { validation.touched.id_sub_request_type && validation.errors.id_sub_request_type ? 
                          ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.id_sub_request_type}</FormFeedback> ) : false 
                      }
                  </FormGroup>
              </Col>
          </Row>
          <Row>
          <div className="page-footer">
              <div className="layout-width-footer">
                <div className="navbar-footer">
                <>
                  <div className="d-flex align-items-center">
                    <Button 
                        color="outline secondary" 
                        type="button" 
                        onClick={handleBack}
                    >
                    Back
                    </Button>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button 
                        className="float-end button-continue" 
                        color="primary" 
                        type="button" 
                        onClick={() => { 
                            validation.submitForm().then((dataInSelect) => {
                              if (dataInSelect) {
                                typeCallback({
                                    id_request_type: dataInSelect.id_request_type,
                                    id_sub_request_type: dataInSelect.id_sub_request_type,
                                    id_sub_division_request_type: dataInSelect.id_sub_division_request_type,
                                });
                                nextStep();
                              }
                            });
                        }}
                    >
                        Continue
                    </Button>
                  </div>
                </>
                </div>
              </div>
            </div>
          </Row>
        </React.Fragment>
    );
};

export default ChooseRequestForm;