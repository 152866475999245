const ConvertStringDate = (value, oldFormat, newFormat, keyReplace) => {
    let valueReturn = "";
    if(value !== "" && value !== null){
        const splitDate = value.split(keyReplace);
        const splitOldFormat = oldFormat.split(keyReplace);
        let iDay = 0;
        let iMonth = 1;
        let iYear = 2;
        splitOldFormat.forEach(function (value, index) {
            switch(value){
                case "dd" : 
                    iDay = index;
                    break;
                case "MM" : 
                    iMonth = index;
                    break;
                case "yyyy" :
                    iYear = index;
                    break;
            }
        });
        valueReturn = newFormat.replace("yyyy", splitDate[iYear]).replace("MM", splitDate[iMonth]).replace("dd", splitDate[iDay]);
    }
    return valueReturn;
};

const GetFullMonth = (month) => {
    const monthsName = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    return monthsName[month];
};

const ConvertStringDateFullCalendar = (value, format) => {
    const dTime = new Date(value);
    const day = dTime.getDate().toString().padStart(2, '0');
    const month = (dTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dTime.getFullYear().toString();
    const hours = dTime.getHours().toString().padStart(2, '0');
    const minutes = dTime.getMinutes().toString().padStart(2, '0');

    const strReturn = format.replace("dd", day).replace("MM", month).replace("yyyy", year).replace("hh", hours).replace("mm", minutes);

    return strReturn;
};

const ConvertDateToString = (value, format) => {
    const dTime = value;
    const day = dTime.getDate().toString().padStart(2, '0');
    const month = (dTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dTime.getFullYear().toString();
    const hours = dTime.getHours().toString().padStart(2, '0');
    const minutes = dTime.getMinutes().toString().padStart(2, '0');

    const strReturn = format.replace("dd", day).replace("MM", month).replace("yyyy", year).replace("hh", hours).replace("mm", minutes);

    return strReturn;
};

const CheckDateIsToday = (value) => {
    const inputDate = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if(inputDate.toDateString() === today.toDateString()){
        return true;
    }
    return false;
};

const ConvertStringToShortMonth = (value) => {
    if(value !== "" && value !== null){
        const dateObject = new Date(value);
        const day = dateObject.getDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObject);
        const year = dateObject.getFullYear();

        return day + " " + month + " " + year;
    }
    return "";
};

const ConvertStringToFullMonth = (value) => {
    if(value !== "" && value !== null && value !== undefined){
        const dateObject = new Date(value);
        const day = dateObject.getDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObject);
        const year = dateObject.getFullYear();

        return day + " " + month + " " + year;
    }
    return "";
};

const ConvertStringToTime12 = (value) => {
    if(value !== "" && value !== null){
        const dateObject = new Date(value);
        let hours = dateObject.getHours();
        let minutes = dateObject.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    return "";
};

const ConvertStringToTime24 = (value) => {
    if(value !== "" && value !== null){
        const dateObject = new Date(value);
        let hours = dateObject.getHours();
        let minutes = dateObject.getMinutes();
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        const strTime = hours + ':' + minutes;
        return strTime;
    }
    return "";
};

export { ConvertStringDate, GetFullMonth, ConvertStringDateFullCalendar, ConvertDateToString, CheckDateIsToday, ConvertStringToShortMonth, ConvertStringToFullMonth, ConvertStringToTime12, ConvertStringToTime24 };