const modelDataRequest = {
    id:0,
    id_form_temp: 0,
    table_name: "",
    id_workflow_status: 0,
    request_no: "",
    request_date: "",
    contract_no : "",
    id_business_unit : "",
    business_unit_other : "",
    id_confidential_level : 0,
    counterparty_name : "",
    request_by: "",
    require_handled_by: "",
    approve_request_by: "",
    accept_request_by : "",
    is_user_final_doc: "",
    final_doc: "",
    memo_doc: "",
    agreement_doc: "",
    select_user_approve_by: "",
    user1_approve_by: "",
    user2_approve_by: "",
    user3_approve_by: "",
    select_legal_approve_by: "",
    legal1_approve_by: "",
    legal2_approve_by: "",
    legal3_approve_by: "",
    signed_doc: "",
    status: "1",
};

const modelRequestWorkFlow = {
    id: 0,
    id_request: 0,
    id_workflow_status: 0,
    do_by: "",
    send_to: "",
    cc_to: "",
    comment: "",
    document: "",
    do_date: "",
    finish_date: "",
    consent: "",
    status: "1",
};

const modelSendMail = {
    id_request: 0,
    id_workflow_status: 0,
    name_requestor: "",
    email_requestor: "",
    name_approval: "",
    email_approval: "",
    name_legal: "",
    email_legal: "",
};

const modelFormCsa = {
    id: 0,
    id_request:"",
    project_name: "", // csa
    draft_agreement:"", // csa
    draft_doc:"",
    id_business_unit:"",
    business_unit_other:"",
    business_unit_act_as:"",
    counterparty_name:"",
    counterparty_doc:"",
    counterparty_act_as:"",
    purpose:"",
    place_of_work:"",
    scope_of_work:"",
    scope_of_work_doc:"",
    is_sla:"",
    is_sla_doc:"",
    consider_doc:"",
    term_start_date:"",
    term_end_date:"",
    term_other:"",
    term_renewal_condition:"",
    service_fee_total:"",
    vat:"",
    service_fee_payment_type:"",
    service_fee_payment_detail:"",
    service_fee_credit_term:"",
    performance_bond:"",
    penalty_delay_service:"",
    penalty_not_delivered:"",
    penalty_absence_work:"",
    penalty_others:"",
    termination_detail:"",
    ip_ownership_detail:"",
    warranty:"",
    stamp_duty:"",
    other_fee:"",
    other_conditions:"",
    id_language:"", //int
    id_confidential_level:"", //int
    share_pdpa:"",
    user_comment:"",
    require_handled_by: "",
    approve_request_by: "",
    approver_comment: "",
}


export { modelDataRequest, modelRequestWorkFlow, modelSendMail, modelFormCsa}