import React, { useEffect, useState } from 'react';
import MultipleDownloadfile from '../common/multiple-downloadfile';
import { ConvertFirstStringUpperCase, setGroupAndSortByDate, setStringFileNameToArray } from '../utils/format-value';
import { ConvertStringToShortMonth, ConvertStringToTime24 } from '../utils/convert-date';

const RequestHistory = ({data, column, requestNo}) => {
    const [dataHistory, setDataHistory] = useState([]);

    useEffect(() => {
        const dataGroup = setGroupAndSortByDate(data, column);
        setDataHistory(dataGroup);
    }, [data]);

    const OnClickShowCommnet = (id) => {
        const dataAll = dataHistory.map(item => {
            return {
                ...item,
                value: item.value.map(subItem => {
                    return subItem.id === id ? {...subItem, show: !subItem.show} : subItem;
                })
            };
        });
        setDataHistory(dataAll);
    };

    return (
        <React.Fragment>
            {(dataHistory || []).map((val, key) => {
                return(
                    <div key={"history" + key} className="history-request">
                        <p className="hr-group-title">{ConvertStringToShortMonth(val.key)}</p>
                        {(val.value || []).map((subVal, subKey) => {
                            return(
                                <div key={"historySub" + key + subKey} className="hr-desc-container">
                                    <div className="hr-desc-item-title-icon">
                                        <div className="hr-title-icon-circle"></div>
                                    </div>
                                    <div className="hr-desc-item-title-detail">
                                        {/* <p>{ConvertFirstStringUpperCase(subVal.name_group_workflow)} </p> */}
                                        <p onClick={ (e) => { OnClickShowCommnet(subVal.id); } } >
                                            {ConvertFirstStringUpperCase(subVal.name_workflow)}
                                            <i className={"mdi " + ((subVal.show) ? "mdi-chevron-up" : "mdi-chevron-down")}></i>
                                        </p>
                                    </div>
                                    <div className="hr-desc-item-title-time">
                                        <p>{ConvertStringToTime24(subVal[column])}</p>
                                    </div>
                                    <div className="hr-desc-item-desc-icon">
                                        {((key < dataHistory.length - 1) || (subKey < val.value.length - 1)) ? <div className="hr-desc-icon-line"></div> : null}
                                    </div>
                                    <div className="hr-desc-item-desc">
                                        {/* <p className="hr-item-desc-type">{ConvertFirstStringUpperCase(subVal.name_workflow)}</p> */}
                                        {
                                            (subVal.show) ? 
                                                (subVal.comment !== "") ? 
                                                    <div className="hr-item-desc-comment">
                                                        <span className="hr-item-desc-comment-label">Comment : </span>
                                                        <span className="hr-item-desc-comment-span" dangerouslySetInnerHTML={{__html: subVal.comment}} />
                                                        <div className="hr-item-desc-comment-files">
                                                            <MultipleDownloadfile folder={requestNo} dataFile={setStringFileNameToArray(subVal.document)} />
                                                        </div>
                                                    </div> 
                                                    : <p className="hr-item-desc-comment">Comment : -</p>
                                                : null
                                        }
                                        <p className="hr-item-desc-by">By {subVal.do_by}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default RequestHistory;