import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { setShowFileName } from '../utils/format-value';
import axios from "axios";
import ConfigData from "../../config.json";

const MultipleUploadfile = ({inputID, folder, dataFile, setDataFile, isDisable}) => {
    const ShowDocs = () => {
        // const fileShows = Array.from(fileDocs);
        if(dataFile.length > 0) {
            return (
                <ul className="form-upload-multi-ul">
                    {dataFile.map((val, key) => {
                        return(
                            <li className="form-upload-multi-li" key={"userFinalDoc" + key}>
                                <div className="form-upload-multi-content" >
                                    <label onClick={(e) => { OnClickOpenFile(val.name, val.file); }}>{setShowFileName(val.name)}</label>
                                    <i className="mdi mdi-close" onClick={(e) => { OnClickDeleteFile(val.name) }}  ></i>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }else {
            return null;
        }
    };

    const OnChangeInputFile= (e) => {
        let arrDataFile = dataFile.filter((e) => {
            return e.file === null;
        });
        for(let i = 0; i < e.target.files.length; i++){
            const file = e.target.files[i];
            if(file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png"
                || file.type === "application/pdf" || file.type === "application/msword"
                || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
                arrDataFile.push({
                    name: file.name,
                    file: file
                });
            }
        }
        setDataFile(arrDataFile);
    };

    const OnClickDeleteFile = (fileName) => {
        const fileAfterDelete = dataFile.filter((e) => {
            return e.name !== fileName;
        });
        setDataFile(fileAfterDelete);
    };

    const OnClickOpenFile = (fileName, file) => {
        if(file === null){
            let url = new URL(ConfigData.API_URL + "file/read");
            url.searchParams.set("filename", fileName);
            url.searchParams.set("folder", folder);
            let token = "Bearer " + localStorage.getItem("tkd");
            axios.get(url, { headers: { 
                'Authorization' : token ,
            }, responseType: "json" })
            .then(resp => {
                let respData = resp.data;
                if(respData.is_sucess === true){
                    window.open(respData.data.url);
                }
            })
            .catch(error => {
                // handlerRespError(error, history);
                console.log(error);
            });
        }
    };

    return (
        <React.Fragment>
            <div className="form-upload-multi">
                {ShowDocs()}
                <Input id={inputID}
                    className="form-upload-multi-file-input"
                    type="file"
                    multiple
                    accept="image/*,.pdf,.doc,.docx"
                    onChange={OnChangeInputFile}
                    disabled={((inputID === 'isSlaDocInActive' && dataFile.length === 0) || (isDisable === true))}
                />
                <Label htmlFor={inputID} className={((inputID === 'isSlaDocInActive' && dataFile.length === 0) || (isDisable === true)) ? "form-upload-multi-file-is-sla-doc" : "form-upload-multi-file-label"}>
                    <i className="mdi mdi-rotate-90 mdi-attachment"></i>
                    Attachment File
                </Label>
            </div>
        </React.Fragment>
    );
};

export default MultipleUploadfile;