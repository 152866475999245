import { Redirect } from 'react-router-dom';
import Login from '../pages/authentication/login';
import Register from '../pages/authentication/register';
import UserList from '../pages/settings/user-list';
import Dashboard from '../pages/operation/dashboard';
import ReviewComment from '../pages/operation/review-comment';
import Memo from '../pages/operation/memo';
import PrefinalContractLegal from '../pages/operation/prefinal-contract-legal';
import PrefinalContractUser from '../pages/operation/prefinal-contract-user';
import PrefinalContractReject from '../pages/operation/prefinal-contract-reject';
import FinalContract from '../pages/operation/final-contract';
import SignedContract from '../pages/operation/signed-contract';
import CreateRequest from '../pages/request/create-request';
import RequestList from '../pages/request/request-list';
import RequestDetail from '../pages/request/request-detail';
import AssignWork from "../pages/operation/assign-work";
import AcceptAssign from "../pages/operation/accept-assign";
import SummaryRequest from '../pages/operation/summary-request';

const authRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: UserList },
  { path: "/assign/:id", component: AssignWork },
  { path: "/accept/:id", component: AcceptAssign },
  { path: "/review-comment/:id", component: ReviewComment },
  { path: "/memo/:id", component: Memo },
  { path: "/prefinal-legal/:id", component: PrefinalContractLegal },
  { path: "/prefinal-user/:id", component: PrefinalContractUser },
  { path: "/prefinal-reject/:id", component: PrefinalContractReject },
  { path: "/final-contract/:id", component: FinalContract },
  { path: "/signed-contract/:id", component: SignedContract },
  { path: "/create-request", component: CreateRequest },
  { path: "/requests", component: RequestList },
  { path: "/request/:idRequest", component: RequestDetail },
  { path: "/summary-request/:id", component: SummaryRequest },
  // { path: "/add-user", component: UserList},
  // { path: "/user-detail/:id", component: UserList},
  {
      path: "/",
      expect: true,
      component: () => <Redirect to="/requests" />,
  }
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register/:email", component: Register },
];

export { authRoutes, publicRoutes };