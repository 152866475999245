import React, { useEffect, useState } from 'react'

const RequestFinalApprover = ({dataReq, lastApprover}) => {
    const [arrApprover, setArrApprover] = useState([]);
    const [nextApprover, setNextApprover] = useState("");

    useEffect(() => {
        let dataArrApprover = SetListApprover(dataReq);
        setArrApprover(dataArrApprover);
    }, [dataReq]);

    const SetListApprover = (dataRequest) => {
        const arrData = [];
        if(dataRequest.legal1_approve_by !== ""){
            arrData.push(dataRequest.legal1_approve_by);
        }
        if(dataRequest.legal2_approve_by !== ""){
            arrData.push(dataRequest.legal2_approve_by);
        }
        if(dataRequest.legal3_approve_by !== ""){
            arrData.push(dataRequest.legal3_approve_by);
        }
        if(dataRequest.user1_approve_by !== ""){
            arrData.push(dataRequest.user1_approve_by);
        }
        if(dataRequest.user2_approve_by !== ""){
            arrData.push(dataRequest.user2_approve_by);
        }
        if(dataRequest.user3_approve_by !== ""){
            arrData.push(dataRequest.user3_approve_by);
        }
        return arrData;
    };

    const CheckClassStatusApprover = (indexApprover) => {
        const arrIndexApprover = arrApprover.indexOf(lastApprover) + 1;
        if(indexApprover === arrIndexApprover){
            return "active";
        }else if(indexApprover < arrIndexApprover){
            return "sucess";
        }else{
            return "";
        }
    };

    const CheckIconStatusApprover = (indexApprover) => {
        const arrIndexApprover = arrApprover.indexOf(lastApprover) + 1;
        if(indexApprover === arrIndexApprover){
            return <p>{indexApprover + 1}</p>;
        }else if(indexApprover < arrIndexApprover){
            return <i className="mdi mdi-check"></i>;
        }else{
            return <p>{indexApprover + 1}</p>;
        }
    };

    return (
        <div className="request-final-approver">
            {(arrApprover || []).map((val, key) => {
                return(
                    <div key={"approver-final" + key} className="rfa-container">
                        <div className="rfa-item-desc-icon">
                            <div className={"rfa-desc-icon-circle " + CheckClassStatusApprover(key)}>
                                {CheckIconStatusApprover(key)}
                            </div>
                        </div>
                        <div className="rfa-item-desc-detail">
                            <p className={"rfa-item-desc-detail-label " + CheckClassStatusApprover(key)}>{val}</p>
                        </div>
                        <div className="rfa-item-desc-line">
                            {(key < arrApprover.length - 1) ? <div className="rfa-desc-icon-line"></div> : null}
                        </div>
                        <div className="rfa-item-desc-space"></div>
                    </div>
                );
            })}
        </div>
    );
};

export default RequestFinalApprover;